import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegistrationService } from 'src/app/services/registration.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss'
}) 
export class SignUpComponent {
  public show: boolean = false;
  public showCnf: boolean = false;
  signup!: FormGroup;
  email: any;
  password: any;
  conformpass: any;
  inputValue: any;
  submitted = false;
  showPassword1: boolean;
  username: any;


  public config = {
    settings: {
      layout: 'Dubai',
      layout_type: 'ltr',
      layout_version: 'light-only',
      sidebar_type: 'default-sidebar',
      icon: 'stroke-svg'
    },
    color: {
      primary_color: '#3a5880', 
      secondary_color: '#7ea3d0'
    }
  }
  agree1: boolean=false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private registration: RegistrationService
  ) {
    if(this.config.settings.layout_type == 'rtl')
    document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);
    document.documentElement.style.setProperty('--theme-deafult', this.config.color.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.config.color.secondary_color);
   }

  ngOnInit() {
    this.formInit();

  }

  showPassword() {
    this.show = !this.show;
  }
  showCnfPassword() {
    this.showCnf = !this.showCnf;
  }


  formInit() {
    this.signup = this.formBuilder.group({
      email: ['', [Validators.pattern(/^([^\s@]+@[^\s@]+\.[^\s@]+)|([1-9]\d{9})$/)]],
      password: ['', [Validators.required, Validators.minLength(8)], Validators.maxLength(20)],
      conformpass: ['', Validators.required],
      agree: [false, Validators.requiredTrue]
    });
  }

  async continue() {
    const agree=this.signup.get('agree').value;
    console.log('agree-->',agree);
    // this.signup.get('email').value;
    this.username = this.signup.get('email').value;
    this.password = await this.signup.controls["password"].value;
    this.conformpass = await this.signup.controls["conformpass"].value;

    console.log('this.username---->', this.username);
   
    localStorage.setItem('email', this.username);
  
  if(!this.username ||  !this.password || !this.conformpass){
    Swal.fire({
      icon: 'error',
      title: 'Alert',
      text: 'Please Fill All Required Field',
      confirmButtonText: 'Ok',
    });
  } else if(!this.isValidMobileOrEmail(this.signup.get('email')?.value)){
   
  }
 
  else{
   
    console.log('else ');
  this.registration.checkUserExist(this.username).subscribe(
    (data: any) => {
      // Handle successful response
      console.log('Data:', data);

       Swal.fire({
          icon: 'error',
          title: 'Alert',
          text: 'User Name is already exist.',
          confirmButtonText: 'Ok',
        });
        console.log('----404--1--')
      },
      async (error) => {
        // Handle errors
        console.error('Error:', error);
    
        // You can perform additional actions based on the error status code or message
        if (error.status === 404) {
          this.email = await this.signup.controls["email"].value;
              console.log('this.email', this.email);
              localStorage.setItem('email', this.email);
          
              this.password = await this.signup.controls["password"].value;
              console.log('this.password', this.password);
              localStorage.setItem('password', this.password)
          
              this.conformpass = await this.signup.controls["conformpass"].value;
              console.log('this.conformpass', this.conformpass);
              if (this.password.length < 8) {
                this.signup.setErrors({ minlength: true });
                
                console.log('minlength ');
              }else 
               if (this.password !== this.conformpass) {
            this.signup.setErrors({ passwordMismatch: true });
          }
         else if(!(this.signup.get('agree')?.value ===true)){
            this.agree1=true;
           }
           else {
            this.signup.setErrors(null);
            this.router.navigateByUrl('/auth/register');
      
          }
        } else if (error.status === 500) {
          Swal.fire({
            icon: 'error',
            title: 'Alert',
            text: 'Please Try again...',
            confirmButtonText: 'Ok',
          });       
         } else {
          console.log('Other error');
        }
    
        // You can also throw the error again if needed
        throw error;
    
      })
    }
  }

  async keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    // const pattern = ("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
    this.inputValue = event.value;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar) || this.inputValue < 10) {
      event.preventDefault();
    }
  }

  gotoNextField(nextElement: any) {
    nextElement.setFocus();
    console.log(nextElement.setFocus());

  }

  isValidMobileOrEmail(value: string): boolean {
    // const mobileRegex = /^[1-9]\d{9}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // return mobileRegex.test(value) || emailRegex.test(value);
    return emailRegex.test(value);

  }
onPaste(event: ClipboardEvent): void {
  event.preventDefault(); // Prevent the default paste behavior
  }
  
  onCopy(event: ClipboardEvent): void {
  event.preventDefault(); // Prevent the default copy behavior
  }
  checkBox(){
    this.agree1=false;
  }
}