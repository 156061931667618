<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">     
        <div class="login-card">
          <div>
            <!-- <div>
              <a class="logo" >
                <img class="img-fluid for-light" height="40px"  width="180px" src="../../../assets/images/Impartr_Logo.png" alt="looginpage">
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
              </a>
            </div> -->
            <div>
              <a class="logo text-center" >
  
                <img class="img-fluid for-light" height="40px" width="180px"
                  src="../../../assets/images/Impartr_Logo_light.png" alt="looginpage">
                <img class="img-fluid for-dark" height="40px" width="180px"
                  src="../../../assets/images/Impartr_Logo_dark.png" alt="looginpage">
              </a>
            </div>
            <div class="login-main"> 
              <form  [formGroup]="forgetPass" class="theme-form" autocomplete="off">
                <h4>{{'Reset Your Password' | translate}}</h4>
                <div class="form-group">
                  <label class="col-form-label">{{'Enter Your User Name(Email)' | translate}}</label>
                  <div class="row">
                    <div class="col-md-12">
                      <input class="form-control" formControlName="email" type="email" required=""
                      placeholder="app@yourmail.com" readonly>
                  </div>
                  <div *ngIf="!isValidEmail(forgetPass.get('email')?.value) && forgetPass.get('email')?.touched"
                        class="text-danger">
                        {{'Please enter a valid email address.' | translate}}
                    </div>
                    <div class="col-12">
                      <button class="btn btn-primary d-block w-100 m-t-10" (click)="sendOtp()" type="submit">Send</button>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-4 mb-4"><span class="reset-password-link">If don't receive OTP?<a class="btn-link text-danger" (click)="sendOtp()" [routerLink]=''> {{'Resend' | translate}}</a></span></div>
                <div class="form-group">
                  <label class="col-form-label pt-0">{{'Enter OTP' | translate}}</label>
                  <div class="row">
                    <div class="col" style="text-align: center;">
                      <ng-otp-input formControlName="otp" class="custom-otp-input" (onInputChange)="onOtpChange($event)"  
                      [config]="{length:5}"></ng-otp-input>                  
                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button class="btn btn-primary d-block w-100 m-t-10" (click)="VerifyOtp()" type="submit">{{'Verify Otp' | translate}}</button>
                  </div>
                </div>
                <div *ngIf="showPass===true">
                <h6 class="mt-4"> {{'Create Your Password' | translate}}</h6>
                <div class="form-group">
                  <label class="col-form-label"> {{'New Password' | translate}}</label>
                  <input class="form-control" formControlName="newPassword" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********">
                  <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                </div>
                <div *ngIf="forgetPass.hasError('minlength') && forgetPass.get('newPassword')?.hasError('minlength')" class="text-danger">
                  {{'Password must be at least 8 characters long.' | translate}}
              </div>
                <div class="form-group">
                  <label class="col-form-label">{{'Confirm Password' | translate}}</label>
                  <input class="form-control" formControlName="conformpass" [type]="show1 ? 'text' : 'password'" name="login[password]" required="" placeholder="*********">
                  <div class="show-hide" (click)="showPassword1()" *ngIf="!show1"><span class="show"></span></div>
                  <div class="show-hide" (click)="showPassword1()" *ngIf="show1"><span class="Hide"></span></div>
                </div>
                <div
                *ngIf="forgetPass.hasError('passwordMismatch') && forgetPass.controls['newPassword'].touched && forgetPass.controls['conformpass'].touched"
                class="text-danger">
                Passwords do not match.
              </div>
              
                <div class="form-group mb-0">
                  <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox">
                    <label class="text-muted" for="checkbox1">Remember password</label>
                  </div>
                  <button class="btn btn-primary d-block w-100" type="submit" (click)="resetPassWord()">Done</button>
                </div>
              </div>
                <p class="mt-4 mb-0">{{'Already have an password?.' | translate}}<a class="ms-2" [routerLink]="''">{{'Sign in' | translate}}</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>