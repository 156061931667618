import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-question3',
  templateUrl: './question3.component.html',
  styleUrls: ['./question3.component.scss']
}) 
export class Question3Component implements OnInit {
  imageSocialMediaManager: boolean = false;
  imageSmallBusinessOwner: boolean = false;
  imageCreator: boolean = false;
  imageMarketingAgency: boolean = false;
  imageFreelancer: boolean = false;
  imageOther: boolean = false;
  element: any;
  constructor(
    private router:Router
  ) { }

  ngOnInit() {
  }
  currentQuestion = 1;
  selectedOption: string;

  selectOption(option: string) {
    this.selectedOption = option;
  }

  nextQuestion() {
    if (this.currentQuestion < 4) {
      this.currentQuestion++;
      console.log(this.currentQuestion);
    } else {
      // Submit the questionnaire
      console.log(this.currentQuestion);

    }
  }

  async onChangeImage(el) {
    console.log('el-->', el);
    this.element=el;
    // just me
    if (el === 1) {
      this.imageSocialMediaManager = true;
      this.imageSmallBusinessOwner = false;
      this.imageCreator = false;
      this.imageMarketingAgency = false;
      this.imageFreelancer = false;
      this.imageOther = false;
      localStorage.setItem('teamsMember', '1')
      console.log('just me--->', await localStorage.getItem('teamsMember'));

    } else
      if (el === 2 || el === 4 || el === 6 || el === 8) {
        this.element='';
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = false;
        this.imageCreator = false;
        this.imageMarketingAgency = false;
        this.imageFreelancer = false;
        this.imageOther = false;
      }
      // 2-3 peoples
      else if (el === 3) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = true;
        this.imageCreator = false;
        this.imageMarketingAgency = false;
        this.imageFreelancer = false;
        this.imageOther = false;
        localStorage.setItem('teamsMember', ' 2-3 peoples')
        console.log(' 2-3 peoples--->', await localStorage.getItem('teamsMember'));

      }
      //4-6 peoples
      else if (el === 5) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = false;
        this.imageCreator = true;
        this.imageMarketingAgency = false;
        this.imageFreelancer = false;
        this.imageOther = false;
        localStorage.setItem('teamsMember', '4-6 peoples')
        console.log('4-6 peoples--->', await localStorage.getItem('teamsMember'));
      }
      //More than 4 people
      else if (el === 7) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = false;
        this.imageCreator = false;
        this.imageMarketingAgency = true;
        this.imageFreelancer = false;
        this.imageOther = false;
        localStorage.setItem('teamsMember', 'more than 4')
        console.log('51- 100 peoples--->', await localStorage.getItem('teamsMember'));
      }
    
  }

  routeToQue4(){
    if(!this.element){
    Swal.fire({
    icon: 'error',
    //title: 'Alert',
    text: "Please Select Your Team Strength",
    confirmButtonText: 'Ok',
    
    });
    }else{
    this.router.navigateByUrl('/questions/question4')
    }
    }

}
