<div class="div">
  <div class="div-8">
    <div class="div-9">
      <div class="div-10">
        <img height="100%" width="60px" src="../../../assets/images/avtar/question2.png">
      </div>
      <div class="div-16">
        <div class="div-17">Question 2 of 4</div>
        <div class="div-18">
          How Big is your Company, including yourself?
        </div>
        <div style="color: #757575;
        text-align: left;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;margin-top: 10px;">Your answer will help us personalize your experience</div>
        
        <div class="div-19">
          <div class="div-20">
              <!--image Just me-->
            <div *ngIf="imageSocialMediaManager===false" (click)="onChangeImage(1)">
              <div class="column" style="padding-bottom: 20px;">
                <div class="option" style="margin-left: 21px;">
                  <div class="div-21">
                    <img loading="lazy" src="../../../assets/images/avtar/Just me.png" class="img-4" />
                    <div class="div-22">
                      Just me
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- color image Just me-->
            <div *ngIf="imageSocialMediaManager===true" (click)="onChangeImage(2)">
              <div class="column" style="text-align: center;padding-bottom: 20px;">
                <div class="option" style="background-color: #3B5880;text-align: center;margin-left: 21px;">
                  <div class="div-21" >
                    <img loading="lazy" src="../../../assets/images/avtar/Just me.png" class="img-4" />
                    <div class="div-22" style="color: #fff;">
                      Just me
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- image   2-10 peoples-->
            <div *ngIf="imageSmallBusinessOwner===false" (click)="onChangeImage(3)">
              <div class="column-2" style="padding-bottom: 20px;">
                <div class="option">
                  <div class="div-23">
                    <img loading="lazy" src="../../../assets/images/avtar/2-10 people.png" class="img-4" />
                    <div class="div-22">
                      2-10 peoples
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- color image    2-10 peoples-->
            <!-- <div *ngIf="imageSmallBusinessOwner===true" (click)="onChangeImage(4)">
              <div class="column" style="text-align: center;">
                <div class="option">
                  <div class="div-23" style="background-color: #3B5880;text-align: center;">
                    <img loading="lazy" src="../../../assets/images/avtar/2-10 people.png" class="img-5" />
                    <div class="div-22" style="color: #fff;">
                      2-10 peoples
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div *ngIf="imageSmallBusinessOwner===true" (click)="onChangeImage(4)">
              <div class="column-2" style="padding-bottom: 20px;">
                <div class="option" style="background-color: #3B5880;text-align: center;">
                  <div class="div-23" >
                    <img loading="lazy" src="../../../assets/images/avtar/2-10 people.png" class="img-4" />
                    <div class="div-22"  style="color: #fff;">
                      2-10 peoples
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="imageCreator===false" (click)="onChangeImage(5)">
              <div class="column-3" style="padding-bottom: 20px;">
                <div class="option">
                  <div class="div-25" >
                    <img loading="lazy" src="../../../assets/images/avtar/11-50 people.png" class="img-4" />
                    <div class="div-22" >11-50 peoples</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- color image  11-50 peoples-->
            <div *ngIf="imageCreator===true" (click)="onChangeImage(6)">
              <div class="column-3" style="padding-bottom: 20px;">
                <div class="option"  style="background-color: #3B5880;text-align: center;">
                  <div class="div-25">
                    <img loading="lazy" src="../../../assets/images/avtar/11-50 people.png" class="img-4" />
                    <div class="div-22" style="color: #fff;">11-50 peoples</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="div-27">
          <div class="div-28">
            <!--51- 100 peoples-->
            <div *ngIf="imageMarketingAgency===false" (click)="onChangeImage(7)">
              <div class="column" style="padding-bottom: 20px;">
                <div class="option" style="margin-left: 21px;">
                  <div class="div-29">
                    <img loading="lazy" src="../../../assets/images/avtar/51-100 people.png" class="img-4"/>
                    <div class="div-22">
                      51- 100 peoples
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- color image  51- 100 peoples-->
            <div *ngIf="imageMarketingAgency===true" (click)="onChangeImage(8)">
              <div class="column" style="padding-bottom: 20px;">
                <div class="option" style="background-color: #3B5880;text-align: center;margin-left: 21px;">
                  <div class="div-29" >
                    <img loading="lazy" src="../../../assets/images/avtar/51-100 people.png" class="img-4" />
                    <div class="div-22" style="color: #fff;">
                      51- 100 peoples
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--image   Over 100 people-->
            <div *ngIf="imageFreelancer===false" (click)="onChangeImage(9)">
              <div class="column-4" style="padding-bottom: 20px;">
                <div class="option">
                  <div class="div-31" >
                    <img loading="lazy" width="200px" src="../../../assets/images/avtar/Over 100.png"class="img-5" />
                    <div class="div-22" >
                      Over 100 people
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- color image   Over 100 people-->
            <div *ngIf="imageFreelancer===true" (click)="onChangeImage(10)">
              <div class="column-4" style="padding-bottom: 20px;">
                <div class="option" style="background-color: #3B5880;text-align: center;">
                  <div class="div-31">
                    <img loading="lazy" width="200px" src="../../../assets/images/avtar/Over 100.png"class="img-5" />
                    <div class="div-22" style="color: #fff;">
                      Over 100 people
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--image Creator-->
            <!-- <div *ngIf="imageOther===false" (click)="onChangeImage(11)">
              <div class="column-4">
                <div class="option">
                  <div class="div-31">
                    <img loading="lazy" src="../../../assets/images/avtar/other.png" class="img-8" />
                    <div class="div-22">Other

                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- color image Other-->
            <!-- <div *ngIf="imageOther===true" (click)="onChangeImage(12)">
              <div class="column-5">
                <div class="option" style="background-color: #3B5880;text-align: center;">
                  <div class="div-22">
                    <img loading="lazy" src="../../../assets/images/avtar/other.png" class="img-5" />
                    <div class="div-34" style="color: #fff;">
                      Other
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="div-35" (click)="routeToQue3()">
      <div class="div-36" >Next</div>
      <img src="../../../assets/icon/arrow-right.png">
    </div>
  </div>
</div>
