<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-md-9">
        <h3>{{ title | translate}}</h3>
        <h6 style="color: rgb(110, 110, 109);">{{subTitle | translate }}</h6>
      </div>
      <!-- <div class="col-md-2"></div> -->
      <div class="col-md-3">

        <div>
          <button type="button" class="btn btn-primary" (click)="createPost()">+ {{'Create Post' | translate }}</button>
        </div>


        <!-- <ol class="breadcrumb">
  <li class="breadcrumb-item">
  <a [routerLink]="'/dashboard/default'">
  <app-svg-icon [icon]="'home'"></app-svg-icon>
  </a>
  </li>
  <li class="breadcrumb-item" *ngFor="let item of items">{{ item }}</li>
  <li class="breadcrumb-item active">{{ active_item }}</li>
  </ol> -->
      </div>
    </div>
  </div>
</div>
