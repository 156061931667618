import { Component, OnInit } from '@angular/core';
import { user } from '@angular/fire/auth';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-question1',
  templateUrl: './question1.component.html',
  styleUrls: ['./question1.component.scss']
})
export class Question1Component implements OnInit {
  imageSocialMediaManager: boolean = false;
  imageSmallBusinessOwner: boolean = false;
  imageCreator: boolean = false;
  imageMarketingAgency: boolean = false;
  imageFreelancer: boolean = false;
  imageOther: boolean = false;
  element: any;
  firstName: string;
  isResponsive: boolean = false;

  constructor(
    private router:Router
  ) { }

 async ngOnInit() {

  this.firstName = await localStorage.getItem('firstName')
    console.log("first Name-->>",this.firstName);
    
  }
  currentQuestion = 1;
  selectedOption: string;

  async selectOption(option: string) {
    this.selectedOption = option;

  }

  nextQuestion() {
    if (this.currentQuestion < 4) {
      this.currentQuestion++;
      console.log(this.currentQuestion);
    } else {
      // Submit the questionnaire
      console.log(this.currentQuestion);

    }
  }

  async onChangeImage(el) {
    this.element=el;
    console.log('el-->', el);
    // Social Media Manager
    if (el === 1) {
      this.imageSocialMediaManager = true;
      this.imageSmallBusinessOwner = false;
      this.imageCreator = false;
      this.imageMarketingAgency = false;
      this.imageFreelancer = false;
      this.imageOther = false;
      localStorage.setItem('useFor', 'SocialMediaManager')
      console.log('SocialMediaManager--->', await localStorage.getItem('useFor'));

    } else
      if (el === 2 || el === 4 || el === 6 || el === 8 || el === 10 || el === 12) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = false;
        this.imageCreator = false;
        this.imageMarketingAgency = false;
        this.imageFreelancer = false;
        this.imageOther = false;
        this.element='';
        
      }
      //Small Business Owner
      else if (el === 3) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = true;
        this.imageCreator = false;
        this.imageMarketingAgency = false;
        this.imageFreelancer = false;
        this.imageOther = false;
        localStorage.setItem('useFor', 'SmallBusinessOwner')
        console.log('SmallBusinessOwner--->', await localStorage.getItem('useFor'));

      }
      //Creator
      else if (el === 5) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = false;
        this.imageCreator = true;
        this.imageMarketingAgency = false;
        this.imageFreelancer = false;
        this.imageOther = false;
        localStorage.setItem('useFor', 'Creator')
        console.log('Creator--->', await localStorage.getItem('useFor'));
      }
      //MarketingAgency
      else if (el === 7) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = false;
        this.imageCreator = false;
        this.imageMarketingAgency = true;
        this.imageFreelancer = false;
        this.imageOther = false;
        localStorage.setItem('useFor', 'MarketingAgency')
        console.log('MarketingAgency--->', await localStorage.getItem('useFor'));
      }
      //Freelancer
      else if (el === 9) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = false;
        this.imageCreator = false;
        this.imageMarketingAgency = false;
        this.imageFreelancer = true;
        this.imageOther = false;
        localStorage.setItem('useFor', 'Freelancer')
        console.log('Freelancer--->', await localStorage.getItem('useFor'));
      }
      //Other
      else if (el === 11) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = false;
        this.imageCreator = false;
        this.imageMarketingAgency = false;
        this.imageFreelancer = false;
        this.imageOther = true;
        localStorage.setItem('useFor', 'Other')
        console.log('Other--->', await localStorage.getItem('useFor'));
      }
  }
  routeToQue2(){
    if(!this.element){
    Swal.fire({
    icon: 'error',
    //title: 'Alert',
    text: 'Please Select Your Role To Proceed',
    confirmButtonText: 'Ok',
    
    });
    }else{
    this.router.navigateByUrl('/questions/question2')
    }
    }

}
