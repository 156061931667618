import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegistrationModel } from 'src/app/models/registration-model';
import { RegistrationService } from 'src/app/services/registration.service';

import { VerifyOtp } from '../../models/verify-otp';
import { VerifyOtpService } from '../../services/verify-otp.service';
import Swal from 'sweetalert2';
import { NavService } from 'src/app/shared/services/nav.service';
import { error } from 'console';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent {
  public show: boolean = false;
  countries: any[] | undefined;
  signin!: FormGroup;
  inputValue: any;
  email: any;
  selectedCountry: any;
  fName: any;
  lName: any;
  number: any
  myForm!: FormGroup;
  // myForm: FormGroup = new FormGroup({});
  fname1: boolean = false;
  userData: RegistrationModel = new RegistrationModel();
  otpData: VerifyOtp = new VerifyOtp();
  firstName: any;
  lastName: any;
  countryCode: any;
  mobileCode: any;
  country: any;
  userData1: any;
  password: any;
  alert: any;
  url: any;
  selectedCode: any;
  uid: any;
  userData2: any;
  token: any;
  token1: any;
  public loading = false;
  country1: any;

  //flag
  public language: boolean = false;

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
  {
    language: 'Español',
    code: 'es',
    icon: 'es'
  },
  {
    language: 'Français',
    code: 'fr',
    icon: 'fr'
  },
  {
    language: 'Português',
    code: 'pt',
    type: 'BR',
    icon: 'pt'
  }]

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }


  changeLanguage(lang) {

    this.selectedLanguage = lang;
  }


  public config = {
    settings: {
      layout: 'Dubai',
      layout_type: 'ltr',
      layout_version: 'light-only',
      sidebar_type: 'default-sidebar',
      icon: 'stroke-svg'
    },
    color: {
      primary_color: '#3a5880',
      secondary_color: '#7ea3d0'
    }
  }


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private registrationservice: RegistrationService,
    private otpservice: VerifyOtpService,
    //flag
    public navServices: NavService,
  ) {
    if (this.config.settings.layout_type == 'rtl')
      document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);
    document.documentElement.style.setProperty('--theme-deafult', this.config.color.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.config.color.secondary_color);

  }

  ngOnInit() {
    this.formInit()
    this.email = localStorage.getItem('email');
    console.log(this.email);

    this.email = localStorage.getItem('email');
    console.log(this.email);

    this.password = localStorage.getItem('password')
    console.log('password', this.password)

    this.countries = [
      { code: '93', shortName: 'AF', name: 'Afghanistan' },
      { code: '358', shortName: 'AX', name: 'Aland Islands' },
      { code: '355', shortName: 'AL', name: 'Albania' },
      { code: '213', shortName: 'DZ', name: 'Algeria' },
      { code: '1', shortName: 'AS', name: 'American Samoa' },
      { code: '376', shortName: 'AD', name: 'Andorra' },
      { code: '244', shortName: 'AO', name: 'Angola' },
      { code: '1', shortName: 'AI', name: 'Anguilla' },
      { code: '672', shortName: 'AQ', name: 'Antarctica' },
      { code: '1', shortName: 'AG', name: 'Antigua and Barbuda' },
      { code: '54', shortName: 'AR', name: 'Argentina' },
      { code: '374', shortName: 'AM', name: 'Armenia' },
      { code: '297', shortName: 'AW', name: 'Aruba' },
      { code: '61', shortName: 'AU', name: 'Australia' },
      { code: '43', shortName: 'AT', name: 'Austria' },
      { code: '994', shortName: 'AZ', name: 'Azerbaijan' },
      { code: '1', shortName: 'BS', name: 'Bahamas' },
      { code: '973', shortName: 'BH', name: 'Bahrain' },
      { code: '880', shortName: 'BD', name: 'Bangladesh' },
      { code: '1', shortName: 'BB', name: 'Barbados', },
      { code: '375', shortName: 'BY', name: 'Belarus' },
      { code: '32', shortName: 'BE', name: 'Belgium' },
      { code: '501', shortName: 'BZ', name: 'Belize' },
      { code: '229', shortName: 'BJ', name: 'Benin' },
      { code: '1', shortName: 'BM', name: 'Bermuda' },
      { code: '975', shortName: 'BT', name: 'Bhutan' },
      { code: '591', shortName: 'BO', name: 'Bolivia' },
      { code: '387', shortName: 'BA', name: 'Bosnia and Herzegovina' },
      { code: '267', shortName: 'BW', name: 'Botswana' },
      { code: '55', shortName: 'BR', name: 'Brazil' },
      { code: '246', shortName: 'IO', name: 'British Indian Ocean Territory' },
      { code: '1', shortName: 'VG', name: 'British Virgin Islands' },
      { code: '673', shortName: 'BN', name: 'Brunei Darussalam' },
      { code: '359', shortName: 'BG', name: 'Bulgaria' },
      { code: '226', shortName: 'BF', name: 'Burkina Faso' },
      { code: '257', shortName: 'BI', name: 'Burundi' },
      //C
      { code: '855', shortName: 'KH', name: 'Cambodia' },
      { code: '237', shortName: 'CM', name: 'Cameroon' },
      { code: '1', shortName: 'CA', name: 'Canada' },
      { code: '238', shortName: 'CV', name: 'Cape Verde' },
      { code: '1', shortName: 'KY', name: 'Cayman Islands' },
      { code: '236', shortName: 'CF', name: 'Central African Republic' },
      { code: '235', shortName: 'TD', name: 'Chad' },
      { code: '56', shortName: 'CL', name: 'Chile' },
      { code: '86', shortName: 'CN', name: 'China' },
      { code: '61', shortName: 'CX', name: 'Christmas Island' },
      { code: '61', shortName: 'CC', name: 'Cocos (Keeling) Islands' },
      { code: '57', shortName: 'CO', name: 'Colombia' },
      { code: '269', shortName: 'KM', name: 'Comoros' },
      { code: '242', shortName: 'CG', name: 'Congo (Brazzaville)' },
      { code: '243', shortName: 'CD', name: 'Congo, (Kinshasa)' },
      { code: '682', shortName: 'CK', name: 'Cook Islands' },
      { code: '506', shortName: 'CR', name: 'Costa Rica' },
      { code: '225', shortName: 'CI', name: "Côte d'Ivoire" },
      { code: '385', shortName: 'HR', name: 'Croatia' },
      { code: '53', shortName: 'CU', name: 'Cuba' },
      { code: '599', shortName: 'CW', name: 'Curacao' },
      { code: '357', shortName: 'CY', name: 'Cyprus' },
      { code: '420', shortName: 'CZ', name: 'Czech Republic' },
      //D
      { code: '45', shortName: 'DK', name: 'Denmark' },
      { code: '253', shortName: 'DJ', name: 'Djibouti' },
      { code: '1', shortName: 'DM', name: 'Dominica' },
      { code: '1', shortName: 'DO', name: 'Dominican Republic' },
      //E
      { code: '593', shortName: 'EC', name: 'Ecuador' },
      { code: '20', shortName: 'EG', name: 'Egypt' },
      { code: '503', shortName: 'SV', name: 'El Salvador' },
      { code: '240', shortName: 'GQ', name: 'Equatorial Guinea' },
      { code: '291', shortName: 'ER', name: 'Eritrea' },
      { code: '372', shortName: 'EE', name: 'Estonia' },
      { code: '251', shortName: 'ET', name: 'Ethiopia' },
      //F
      { code: '500', shortName: 'FK', name: 'Falkland Islands (Malvinas)' },
      { code: '298', shortName: 'FO', name: 'Faroe Islands' },
      { code: '679', shortName: 'FJ', name: 'Fiji' },
      { code: '358', shortName: 'FI', name: 'Finland' },
      { code: '33', shortName: 'FR', name: 'France' },
      { code: '594', shortName: 'GF', name: 'French Guiana' },
      { code: '689', shortName: 'PF', name: 'French Polynesia' },
      //G
      { code: '241', shortName: 'GA', name: 'Gabon' },
      { code: '220', shortName: 'GM', name: 'Gambia' },
      { code: '995', shortName: 'GE', name: 'Georgia' },
      { code: '49', shortName: 'DE', name: 'Germany' },
      { code: '233', shortName: 'GH', name: 'Ghana' },
      { code: '350', shortName: 'GI', name: 'Gibraltar' },
      { code: '30', shortName: 'GR', name: 'Greece' },
      { code: '299', shortName: 'GL', name: 'Greenland' },
      { code: '1', shortName: 'GD', name: 'Grenada' },
      { code: '590', shortName: 'GP', name: 'Guadeloupe' },
      { code: '1', shortName: 'GU', name: 'Guam' },
      { code: '502', shortName: 'GT', name: 'Guatemala' },
      { code: '44', shortName: 'GG', name: 'Guernsey' },
      { code: '224', shortName: 'GN', name: 'Guinea' },
      { code: '245', shortName: 'GW', name: 'Guinea-Bissau' },
      { code: '592', shortName: 'GY', name: 'Guyana' },
      //H
      { code: '509', shortName: 'HT', name: 'Haiti' },
      { code: '379', shortName: 'VA', name: 'Holy See(Vatican City State)' },
      { code: '504', shortName: 'HN', name: 'Honduras' },
      { code: '852', shortName: 'HK', name: 'Hong Kong' },
      { code: '36', shortName: 'HU', name: 'Hungary' },
      //I
      { code: '354', shortName: 'IS', name: 'Iceland' },
      { code: '91', shortName: 'IN', name: 'India' },
      { code: '62', shortName: 'ID', name: 'Indonesia' },
      { code: '98', shortName: 'IR', name: 'Iran,Islamic Republic of' },
      { code: '964', shortName: 'IQ', name: 'Iraq' },
      { code: '353', shortName: 'IE', name: 'Ireland' },
      { code: '44', shortName: 'IM', name: 'Isle of Man' },
      { code: '972', shortName: 'IL', name: 'Israel' },
      { code: '39', shortName: 'IT', name: 'Italy' },
      //J
      { code: '1', shortName: 'JM', name: 'Jamaica' },
      { code: '81', shortName: 'JP', name: 'Japan' },
      { code: '44', shortName: 'JE', name: 'Jersey' },
      { code: '962', shortName: 'JO', name: 'Jordan' },
      //K
      { code: '7', shortName: 'KZ', name: 'Kazakhstan' },
      { code: '254', shortName: 'KE', name: 'Kenya' },
      { code: '686', shortName: 'KI', name: 'Kiribati' },
      { code: '383', shortName: 'XK', name: 'Kosova' },
      { code: '965', shortName: 'KW', name: 'Kuwait' },
      { code: '996', shortName: 'KG', name: 'Kyrgyzstan' },
      //L
      { code: '856', shortName: 'LA', name: 'Lao PDR' },
      { code: '371', shortName: 'LV', name: 'Latvia' },
      { code: '961', shortName: 'LB', name: 'Lebanon' },
      { code: '266', shortName: 'LS', name: 'Lesotho' },
      { code: '231', shortName: 'LR', name: 'Liberia' },
      { code: '218', shortName: 'LY', name: 'Libya' },
      { code: '423', shortName: 'LI', name: 'Liechtenstein' },
      { code: '370', shortName: 'LT', name: 'Lithuania' },
      { code: '356', shortName: 'LU', name: 'Luxembourg' },
      //M
      { code: '853', shortName: 'MO', name: 'Macau' },
      { code: '389', shortName: 'MK', name: 'Macedonia' },
      { code: '261', shortName: 'MG', name: 'Madagascar' },
      { code: '265', shortName: 'MW', name: 'Malawi' },
      { code: '60', shortName: 'MY', name: 'Malaysia' },
      { code: '960', shortName: 'MV', name: 'Maldives' },
      { code: '223', shortName: 'ML', name: 'Mali'},
      { code: '356', shortName: 'MT', name: 'Malta' },
      { code: '692', shortName: 'MH', name: 'Marshall Islands'},
      { code: '596', shortName: 'MQ', name: 'Martinique'},
      { code: '222', shortName: 'MR', name: 'Mauritania'},
      { code: '230', shortName: 'MU', name: 'Mauritius'},
      { code: '262', shortName: 'YT', name: 'Mayotte' },
      { code: '52', shortName: 'MX', name: 'Mexico' },
      { code: '691', shortName: 'FM', name: 'Micronesia,Federated States of' },
      { code: '373', shortName: 'MD', name: 'Moldova' },
      { code: '377', shortName: 'MC', name: 'Monaco' },
      { code: '976', shortName: 'MN', name: 'Mongolia' },
      { code: '382', shortName: 'ME', name: 'Montenegro' },
      { code: '1', shortName: 'MS', name: 'Montserrat' },
      { code: '212', shortName: 'MA', name: 'Morocco' },
      { code: '258', shortName: 'MZ', name: 'Mozambique' },
      { code: '95', shortName: 'MM', name: 'Myanmar' },
      //N
      { code: '264', shortName: 'NA', name: 'Namibia'},
      { code: '674', shortName: 'NR', name: 'Nauru'},
      { code: '977', shortName: 'NP', name: 'Nepal'},
      { code: '31', shortName: 'NL', name: 'Netherlands'},
      { code: '687', shortName: 'NC', name: 'New Caledonia'},
      { code: '64', shortName: 'NZ', name: 'New Zealand' },
      { code: '505', shortName: 'NI', name: 'Nicaragua' },
      { code: '227', shortName: 'NE', name: 'Niger' },
      { code: '234', shortName: 'NG', name: 'Nigeria'},
      { code: '683', shortName: 'NU', name: 'Niue'},
      { code: '672', shortName: 'NF', name: 'Norfolk Island'},
      { code: '850', shortName: 'KP', name: 'North Korea' },
      { code: '1', shortName: 'MP', name: 'Northern Mariana Islands'},
      { code: '47', shortName: 'NO', name: 'Norway'},
      //O
      { code: '968', shortName: 'OM', name: 'Oman'},
      //P
      { code: '92', shortName: 'PK', name: 'Pakistan'},
      { code: '680', shortName: 'PW', name: 'Palau'},
      { code: '970', shortName: 'PS', name: 'Palestinian Territory'},
      { code: '507', shortName: 'PA', name: 'Panama'},
      { code: '675', shortName: 'PG', name: 'Papua New Guinea'},
      { code: '595', shortName: 'PY', name: 'Paraguay' },
      { code: '51', shortName: 'PE', name: 'Peru' },
      { code: '63', shortName: 'PH', name: 'Philippines'},
      { code: '870', shortName: 'PN', name: 'Pitcairn' },
      { code: '48', shortName: 'PL', name: 'Poland' },
      { code: '351', shortName: 'PT', name: 'Portugal'},
      { code: '1', shortName: 'PR', name: 'Puerto Rico'},
      //Q
      { code: '974', shortName: 'QA', name: 'Qatar'},
      //R
      { code: '262', shortName: 'RE', name: 'Réunion' },
      { code: '40', shortName: 'RO', name: 'Romania'},
      { code: '7', shortName: 'RU', name: 'Russian Federation' },
      { code: '250', shortName: 'RW', name: 'Rwanda' },
      //S
      { code: '590', shortName: 'BL', name: 'Saint-Barthélemy'},
      { code: '290', shortName: 'SH', name: 'Saint Helena' },
      { code: '1', shortName: 'KN', name: 'Saint Kitts and Nevis'},
      { code: '1', shortName: 'LC', name: 'Saint Lucia' },
      { code: '590', shortName: 'MF', name: 'Saint-Martin(French part)' },
      { code: '508', shortName: 'PM', name: 'Saint Pierre and Miquelon'},
      { code: '1', shortName: 'VC', name: 'Saint Vincent and Grenadines'},
      { code: '685', shortName: 'WS', name: 'Samoa'},
      { code: '378', shortName: 'SM', name: 'San Marino'},
      { code: '239', shortName: 'ST', name: 'Sao Tome and Principe'},
      { code: '966', shortName: 'SA', name: 'Saudi Arabia'},
      { code: '221', shortName: 'SN', name: 'Senegal'},
      { code: '381', shortName: 'RS', name: 'Serbia'},
      { code: '248', shortName: 'SC', name: 'Seychelles'},
      { code: '232', shortName: 'SL', name: 'Sierra Leone' },
      { code: '65', shortName: 'SG', name: 'Singapore' },
      { code: '1', shortName: 'SX', name: 'Sint Maarten'},
      { code: '386', shortName: 'SI', name: 'Slovenia'},
      { code: '677', shortName: 'SB', name: 'Solomon Islands'},
      { code: '252', shortName: 'SO', name: 'Somalia'},
      { code: '27', shortName: 'ZA', name: 'South Africa'},
      { code: '82', shortName: 'GS', name: 'South Georgia and the South Sandwich Islands'},
      { code: '211', shortName: 'SS', name: 'South Sudan'},
      { code: '34', shortName: 'ES', name: 'Spain'},
      { code: '94', shortName: 'LK', name: 'Sri Lanka'},
      { code: '249', shortName: 'SD', name: 'Sudan'},
      { code: '597', shortName: 'SR', name: 'Suriname'},
      // { code: ' 855',shortName:'SJ', name: 'Svalbard and Jan Mayen Islands',flagUrl:"../../../assets/img/app_icon_with_name.png" },
      { code: '268', shortName: 'SZ', name: 'Swaziland'},
      { code: '46', shortName: 'SE', name: 'Sweden'},
      { code: '41', shortName: 'CH', name: 'Switzerland'},
      { code: '963', shortName: 'SY', name: 'Syrian Arab Republic (Syria)'},
      //T
      { code: '886', shortName: 'TW', name: 'Taiwan' },
      { code: '992', shortName: 'TJ', name: 'Tajikistan'},
      { code: '255', shortName: 'TZ', name: 'Tanzania, United Republic of' },
      { code: '60', shortName: 'TH', name: 'Thailand' },
      { code: '670', shortName: 'TL', name: 'Timor-Leste'},
      { code: '228', shortName: 'TG', name: 'Togo'},
      { code: '690', shortName: 'TK', name: 'Tokelau'},
      { code: '676', shortName: 'TO', name: 'Tonga'},
      { code: '1', shortName: 'TT', name: 'Trinidad and Tobago'},
      { code: '216', shortName: 'TN', name: 'Tunisia' },
      { code: '90', shortName: 'TR', name: 'Turkey'},
      { code: '993', shortName: 'TM', name: 'Turkmenistan'},
      { code: '1', shortName: 'TC', name: 'Turks and Caicos Islands'},
      { code: '688', shortName: 'TV', name: 'Tuvalu' },
      //U
      { code: '256', shortName: 'UG', name: 'Uganda'},
      { code: '380', shortName: 'UA', name: 'Ukraine'},
      { code: '971', shortName: 'AE', name: 'United Arab Emirates'},
      { code: '44', shortName: 'GB', name: 'United Kingdom' },
      { code: '1', shortName: 'US', name: 'United States of America'},
      { code: '598', shortName: 'UY', name: 'Uruguay'},
      { code: '1', shortName: 'VI', name: 'US Virgin Island'},
      { code: '998', shortName: 'UZ', name: 'Uzbekistan'},
      //V
      { code: '678', shortName: 'VU', name: 'Vanuatu'},
      { code: '58', shortName: 'VE', name: 'Venezuela (Bolivarian Republic)' },
      { code: '84', shortName: 'VN', name: 'Viet Nam'},
      //W
      { code: '681', shortName: 'WF', name: 'Wallis and Futuna Islands' },
      //Y-Z
      { code: '967', shortName: 'YE', name: 'Yemen'},
      { code: '260', shortName: 'ZM', name: 'Zambia' },
      { code: '263', shortName: 'ZW', name: 'Zimbabwe'},
    ];



  }
  formInit() {
    this.myForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.pattern('^[0-9]*$')],
      country: ['', Validators.required]
    });
  }

  showPassword() {
    this.show = !this.show;
  }
  onCountryChange(event: any) {
    if (event.value) {
      this.countryCode = event.value.code;
      localStorage.setItem('countryCode', this.countryCode)
      console.log("countryCode00", this.countryCode)
      this.handleCountrySelection(this.countryCode);
    }
  }

  handleCountrySelection(countryCode: string) {
    console.log('Selected Country Code:', countryCode);
  }

  async keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    this.inputValue = event.value;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar) || this.inputValue < 10) {
      event.preventDefault();
    }
  }
  alphaOnly(event: any) {
    return ((event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode >= 97 && event.keyCode <= 122) || event.keyCode == 32 || event.keyCode == 46);
  }

  async continue() {

    this.userData.firstName = this.myForm.controls["firstName"].value;
    console.log('this.firstName ', this.userData.firstName)
   // await localStorage.setItem('firstName', this.userData.firstName)

    this.userData.lastName = this.myForm.controls["lastName"].value;
    //await localStorage.setItem('lastName', this.userData.lastName)
    console.log(' this.lastName', this.userData.lastName)

    //  this.userData.fullName = this.firstName + ' ' + this.lastName
    // console.log(' this.userData.fullName', this.userData.fullName)

    this.userData.userMobileNumber = this.myForm.controls["phoneNumber"].value
    console.log('this.userData.userMobileNumber', this.userData.userMobileNumber)
    localStorage.setItem('userMobieNumber', this.userData.userMobileNumber)

    this.userData.email = this.myForm.controls["email"].value
    console.log('this.userData.email', this.userData.email)
    localStorage.setItem('email', this.userData.email)


    this.userData.passWord = await localStorage.getItem('password')
    console.log('password', this.userData.passWord)

    // this.country1 = this.myForm.controls["country"].value
    // console.log('this.userData.country', this.userData.country)

    // this.countryCode = this.country1.code;
    // console.log("--------country--------", this.countryCode)

    // this.userData.country = await this.countryCode
    // console.log("--------country1--------", this.userData.country)
    this.userData.userName = await localStorage.getItem('email');
    console.log('email', this.userData.userName)
    this.userData.status = '1'
    this.userData.login_Keyword='Email';

    this.userData.country = await localStorage.getItem('countryCode')
    console.log("--------country1--------", this.userData.country)

    console.log('----------entering into validation-----')
    if (!this.userData.firstName || !this.userData.lastName || !this.userData.country) {
      this.loading = false;
      // loading.dismiss();
      Swal.fire({
        icon: 'error',
        title: 'Alert',
        text: 'Please Fill all the required fields.',
        confirmButtonText: 'Ok',
      });
      console.log('----------entering into validation-----')
      return;

    } else {

      console.log('this.mobileCode---->>>', this.countryCode);
      console.log('---------1--------')

      this.registrationservice.registration(this.userData).subscribe(async (data: any) => {
        console.log('data00000', data)

        if (data["StatusCode"] === 200) {
          this.userData2 = await data
          this.loading = false;

          this.userData1 = data["StatusCode"]
          this.uid = await data['Response'].uid
          this.token = data["Token"];

          console.log('token', this.token)
          //  const token = userData[0]["token"];
          await localStorage.setItem("token", this.token);
          console.log('tokenSet', localStorage.getItem('token'))

          const firstName = data['Response'].firstName
          const lastName = data['Response'].lastName
          const email = data['Response'].email
          const org_Uid= data['Response'].org_Uid
         await localStorage.setItem('firstName', firstName)  
          console.log("firstName from local storage )  ------>>",localStorage.getItem('firstName'));
          
         await localStorage.setItem('lastName', lastName)
          console.log("lastName from local storage )  ------>>",localStorage.getItem('lastName'));

          // await localStorage.setItem('firstName', data['Response'][0]['firstName'])
          // await localStorage.setItem('lastName', data['Response'][0]['lastName'])
          localStorage.setItem('email', email)
          localStorage.setItem('orgId',org_Uid)
          this.router.navigateByUrl('/auth/otp')

          console.log('userId00', this.uid)
          localStorage.setItem('userId', this.uid)

          console.log('data', this.userData1)

          if (data['Message'] === 'Success') {
            console.log('status409', data['StatusCode'])

            console.log("data2-->", data);
            this.loading = false;

            this.userData1 = data["Response"];

            Swal.fire({
              icon: 'success',
              text: 'OTP Send Successfully.',
              confirmButtonText: 'Ok',
            });

            console.log("userData1-->", this.userData1);
            this.router.navigateByUrl('/auth/otp')
          }
        }
      },  async (error) => {
        // Handle errors
        console.error('Error:', error);
        if (error.status === 404) {
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Alert',
          //   text: 'Email Not Registered',
          //   confirmButtonText: 'Ok',
          // });
        }
        else if (error.status === 500) {
          Swal.fire({
            icon: 'error',
            title: 'Alert',
            text: 'Please Try again...',
            confirmButtonText: 'Ok',
          });
        } 
        else if (error.status === 409) {
          Swal.fire({
            icon: 'error',
            title: 'Alert',
            text: 'User Allready Exist',
            confirmButtonText: 'Ok',
            
          }).then((result) => {
            if (result.isConfirmed) {
            
              this.router.navigateByUrl('/auth/login')
            }
          });
        }else {
          console.log('Other error');
        }
        throw error;

      })
    }

    var regex = /[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

    if (typeof this.fName === 'undefined' || this.fName.trim() === '') {
      console.log('Error: Username cannot be blank');
      Swal.fire({
        icon: "error",
        title: "Username cannot be blank",
        text: "Please Enter Valid Username",
      });

    } else if (regex.test(this.fName)) {
      Swal.fire({
        icon: "error",
        title: "Username cannot contain a number or special character",
        text: "Please Enter Valid Username",
      });

      // this.fname1= true;
    } else if (typeof this.lName === 'undefined' || this.lName.trim() === '') {
      console.log('Error: Last name cannot be blank');
      Swal.fire({
        icon: "error",
        title: "Last name cannot be blank",
        text: "Please Enter Valid Last name",
      });

    } else if (regex.test(this.lName)) {
      console.log('Error: Last name contains be a number or special character');
      Swal.fire({
        icon: "error",
        title: "Last name cannot contain a number or special character",
        text: "Please Enter Valid Last name",
        // footer: '<a href="#">Why do I have this issue?</a>'
      });

      // this.fname1= true;
    }
    //this.router.navigateByUrl('/otp')
  }


  // async keyPress(event: any) {
  //   const pattern = /[0-9\+\-\ ]/;
  //   // const pattern = ("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
  //   this.inputValue = event.value;
  //   let inputChar = String.fromCharCode(event.charCode);
  //   if (event.keyCode != 8 && !pattern.test(inputChar) || this.inputValue < 10) {
  //      event.preventDefault();
  //   }
  // }

  // gotoNextField(nextElement: any) {
  // nextElement.setFocus();
  // console.log(nextElement.setFocus());

  // }

  isValidMobileOrEmail(value: string): boolean {
    // const mobileRegex = /^[1-9]\d{9}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // return mobileRegex.test(value) || emailRegex.test(value);
    return emailRegex.test(value);

  }

}
