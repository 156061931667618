import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-question2',
  templateUrl: './question2.component.html',
  styleUrls: ['./question2.component.scss']
})
export class Question2Component implements OnInit {
  imageSocialMediaManager: boolean = false;
  imageSmallBusinessOwner: boolean = false;
  imageCreator: boolean = false;
  imageMarketingAgency: boolean = false;
  imageFreelancer: boolean = false;
  imageOther: boolean = false;
  element: any;
  constructor(
    private router:Router
  ) { }

  ngOnInit() {
  }
  currentQuestion = 1;
  selectedOption: string;

  selectOption(option: string) {
    this.selectedOption = option;
  }

  nextQuestion() {
    if (this.currentQuestion < 4) {
      this.currentQuestion++;
      console.log(this.currentQuestion);
    } else {
      // Submit the questionnaire
      console.log(this.currentQuestion);

    }
  }

  async onChangeImage(el) {
    console.log('el-->', el);
    this.element=el;
    // just me
    if (el === 1) {
    
      this.imageSocialMediaManager = true;
      this.imageSmallBusinessOwner = false;
      this.imageCreator = false;
      this.imageMarketingAgency = false;
      this.imageFreelancer = false;
      this.imageOther = false;
      localStorage.setItem('companyMember', '1')
      console.log('just me--->', await localStorage.getItem('companyMember'));

    } else
      if (el === 2 || el === 4 || el === 6 || el === 8 || el === 10 || el === 12) {
        this.element='';
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = false;
        this.imageCreator = false;
        this.imageMarketingAgency = false;
        this.imageFreelancer = false;
        this.imageOther = false;
      }
      //2-10 peoples
      else if (el === 3) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = true;
        this.imageCreator = false;
        this.imageMarketingAgency = false;
        this.imageFreelancer = false;
        this.imageOther = false;
        localStorage.setItem('companyMember', '2-10')
        console.log('2-10 peoples--->', await localStorage.getItem('companyMember'));

      }
      //11-50 peoples
      else if (el === 5) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = false;
        this.imageCreator = true;
        this.imageMarketingAgency = false;
        this.imageFreelancer = false;
        this.imageOther = false;
        localStorage.setItem('companyMember', '11-50')
        console.log('11-50 peoples--->', await localStorage.getItem('companyMember'));
      }
      //51- 100 peoples
      else if (el === 7) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = false;
        this.imageCreator = false;
        this.imageMarketingAgency = true;
        this.imageFreelancer = false;
        this.imageOther = false;
        localStorage.setItem('companyMember', '51-100')
        console.log('51- 100 peoples--->', await localStorage.getItem('companyMember'));
      }
      //Over 100 people
      else if (el === 9) {
        this.imageSocialMediaManager = false;
        this.imageSmallBusinessOwner = false;
        this.imageCreator = false;
        this.imageMarketingAgency = false;
        this.imageFreelancer = true;
        this.imageOther = false;
        localStorage.setItem('companyMember', 'over 100')
        console.log('Over 100 people--->', await localStorage.getItem('companyMember'));
      }
      //Other
      // else if (el === 11) {
      //   this.imageSocialMediaManager = false;
      //   this.imageSmallBusinessOwner = false;
      //   this.imageCreator = false;
      //   this.imageMarketingAgency = false;
      //   this.imageFreelancer = false;
      //   this.imageOther = true;
      //   localStorage.setItem('companyMember', 'Other')
      //   console.log('Other--->', await localStorage.getItem('companyMember'));
      // }
  }

  routeToQue3(){
    if(!this.element){
    Swal.fire({
    icon: 'error',
    //title: 'Alert',
    text: "Please Select Your Company's Strength",
    confirmButtonText: 'Ok',
    
    });
    }else{
    this.router.navigateByUrl('/questions/question3')
    }
    }

}
