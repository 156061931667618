<nav class="navbar navbar-expand-lg bg-body-tertiary"
    style="border-bottom: 0px solid rgb(187, 187, 187);margin-bottom: 1%;">

</nav>



<div *ngIf="currentStep === 1">

    <div class="div">

        <div class="div-8">
            <div class="div-9">
                <div class="column-3">
                    <div class="div-10">
                        <div class="div-11">
                            <div class="div-12">

                                <img src="../../../assets/icon/Pegination1.png" style="width:60px;height: 100%;">
                            </div>
                            <div class="div-17">
                                <div class="row" style="margin-top: -44%;">
                                    <div class="div-18">Step 1</div>

                                </div>
                                <div class="row">
                                    <div class="div-19">AI for social media</div>

                                </div>
                                <div class="div-20">
                                    Revolutionize your social media strategy with our AI-powered
                                    <br />
                                    planner app. Seamlessly schedule posts across platforms with
                                    <br />
                                    integrated AI algorithms, ensuring optimal timing and audience
                                    <br />
                                    targeting for maximum impact. Elevate your content game
                                    <br />
                                    effortlessly and watch your engagement soar
                                </div>
                            </div>
                        </div>
                        <div class="div-21">
                            <!-- <button (click)="navigateTobackStep()" type="button" style="width: 14%;
padding-left: 9px;" class="btn btn-light" mdbRipple rippleColor="dark">
                                <img src="../../../assets/icon/arrow-left.png">
                            </button> -->
                            <button (click)="navigateToNextStep()" class="btn btn-primary">Continue
                                <img src="../../../assets/icon/arrow-right.png">

                            </button>
                        </div>
                    </div>
                </div>
                <div class="column-4">
                    <img src="../../../assets/icon/Mediapost1.png">
                </div>
            </div>
        </div>
    </div>
</div>


<!-- for second page -->
<div *ngIf="currentStep === 2">

    <div class="div">

        <div class="div-8">
            <div class="div-9">
                <div class="column-3">
                    <div class="div-10">
                        <div class="div-11">
                            <div class="div-12">

                                <img src="../../../assets/icon/Pegination2.png" style="width:60px;height: 100%;">
                            </div>
                            <div class="div-17">
                                <div class="row" style="margin-top: -41%;">
                                    <div class="div-18">Step 2</div>

                                </div>
                                <div class="row">
                                    <div class="div-19">AI Captions & Hashtags</div>

                                </div>
                                <div class="div-20">
                                    Transform your social media game using AI for captivating
                                    <br />
                                    captions and hashtags. This advanced feature leverages
                                    <br />
                                    trend analysis and user insights to offer tailored content
                                    <br />
                                    suggestions, ensuring your posts stand out and resonate
                                    <br />
                                    with your audience. Harness the power of AI to elevate your
                                    <br />
                                    content creation effortlessly.
                                </div>
                            </div>
                        </div>
                        <div class="div-21">
                            <button (click)="navigateTobackStep()" type="button" style="width: 14%;"
                                class="btn btn-light" mdbRipple rippleColor="dark">
                                <img src="../../../assets/icon/arrow-left.png">
                            </button>
                            <!-- <button (click)="navigateToNextStep()" class="btn btn-primary"
                                style="margin-left: 4%;">Continue
                                <img src="../../../assets/icon/arrow-right.png">

                            </button> -->

                            <button (click)="navigateToNextStep()" class="btn btn-primary"
                                style="margin-left: 4%;">Continue
                                <img src="../../../assets/icon/arrow-right.png">

                            </button>
                        </div>
                    </div>
                </div>
                <div class="column-4">
                    <img src="../../../assets/icon/MediadPost2.png" style="height: 73%;
width: 145%;margin-left: -27%;">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- for third page -->

<div *ngIf="currentStep === 3">

    <div class="div">

        <div class="div-8">
            <div class="div-9">
                <div class="column-3">
                    <div class="div-10">
                        <div class="div-11">
                            <div class="div-12">

                                <img src="../../../assets/icon/Pagination3.png" style="width:60px;height: 100%;">
                            </div>
                            <div class="div-17">
                                <div class="row" style="margin-top: -45%;">
                                    <div class="div-18">Step 3</div>
                                </div>
                                <div class="row">
                                    <div class="div-19">Publish</div>
                                </div>
                                <div class="div-20">
                                    Maximize your online impact by effortlessly scheduling and publishing social media
                                    posts.
                                    <br />
                                    Our intuitive platform ensures optimal timing and audience targeting, giving your
                                    content the visibility it deserves.


                                    <br />
                                    Elevate your social media presence with just a few clicks.
                                </div>
                            </div>
                        </div>
                        <div class="div-21" style="margin-top: 1px;">
                            <button (click)="navigateTobackStep()" type="button" style="width: 14%;"
                                class="btn btn-light" mdbRipple rippleColor="dark">
                                <img src="../../../assets/icon/arrow-left.png">
                            </button>
                            <button (click)="navigateToNextStep()" class="btn btn-primary"
                                style="margin-left: 4%;">Continue
                                <img src="../../../assets/icon/arrow-right.png">

                            </button>
                        </div>
                    </div>
                </div>
                <div class="column-4">
                    <img src="../../../assets/icon/mediaPost3.png" style="height: 77%;
width: 145%;
margin-left: -27%;">
                </div>
            </div>
        </div>
    </div>
</div>