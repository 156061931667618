import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationModel } from 'src/app/models/registration-model';
import { RegistrationService } from 'src/app/services/registration.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-question4',
  templateUrl: './question4.component.html',
  styleUrl: './question4.component.scss'
})
export class Question4Component {
  userData: RegistrationModel = new RegistrationModel();

  selectedDiv: string | null = null;
  reason:any;

  selectDiv(divId: string): void {
    this.selectedDiv = divId;
   
  }
  constructor(private registrationservice: RegistrationService,
    private router: Router,
  ) {}

  async userIntrest() {
    if (this.selectedDiv == 'div-22') {
      console.log('div-22');
      this.reason = 'Scheduling and publishing';
    }
    else if (this.selectedDiv == 'div-23') {
      console.log('div-23');
      this.reason = 'Collaborating with creators and brands';
    }
    else if (this.selectedDiv == 'div-24') {
      console.log('div-24');
      this.reason = 'I dont know- I am just trying this out';
    }
    if(this.selectedDiv == null){
      this.reason = null;
      Swal.fire({
        icon: 'error',
        title: 'Alert',
        text: 'Please Select Reason',
        confirmButtonText: 'Ok',
      });
    }
    else{
      this.userData.purposeOfUse = this.reason;
      this.userData.firstName = localStorage.getItem('firstName')
      this.userData.lastName = localStorage.getItem('lastName')
      this.userData.email = localStorage.getItem('email')
      this.userData.useFor = localStorage.getItem('useFor')
      this.userData.teamsMember = localStorage.getItem('teamsMember')
      this.userData.companyMember = localStorage.getItem('companyMember')
      this.userData.userUid = localStorage.getItem('userId');
  
      (await this.registrationservice.question(this.userData)).subscribe((data: any) => {
        if (data['StatusCode'] === 200) {
          this.router.navigateByUrl('/auth/start')
        }
      },
        async (error) => {
          // Handle errors
          console.error('Error:', error);
          if (error.status === 404) {
            Swal.fire({
              icon: 'error',
              title: 'Alert',
              text: 'Data Not Added',
              confirmButtonText: 'Ok',
            });
          }
          else if (error.status === 500) {
            Swal.fire({
              icon: 'error',
              title: 'Alert',
              text: 'Please Try again...',
              confirmButtonText: 'Ok',
            });
          }
          else {
            console.log('Other error');
          }
          throw error;
  
        })
    }
    
   


  }
}
