import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrl: './start.component.scss'
})
export class StartComponent {

  constructor(
    private router:Router
  ){

  }

  routtonext(){
    // localStorage.setItem('tourShown', 'false');
    this.router.navigateByUrl("/auth/create-brand")
  }
}
