import { Component, PLATFORM_ID, Inject } from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { NavService } from './shared/services/nav.service';
import { TranslateService } from '@ngx-translate/core';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  styles: [
    'input { border: 2px solid #3498db; border-radius: 4px; padding: 8px; }'
  ]})
export class AppComponent {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  lang: string;
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,private translate:TranslateService) {
      // this.lang = localStorage.getItem('lang_code')
      // console.log(this.lang);
      // if(this.lang == null){
      //   this.lang = 'en'
      //   localStorage.setItem('lang_code',this.lang)
      // }
      // this.translate.use(this.lang)
  }

}
