<div class="div" >
    <!-- <div class="div-2">
      Your Trial will expire in 14 days !
      <span style="text-decoration: underline">Upgrade Now</span>
    </div> -->
    <!-- <div class="div-3">
      <div class="div-4">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/224ef259b5f72dbb4de3cc6408bc8060e865a434081d64b5a09f7a22a2e8445b?"
          class="img"
        />
        <div class="div-5">
          <img
            loading="lazy"
            srcset="..."
            class="img-2"
          />
          <div class="div-6">
            <div class="div-7">snehal</div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c7749e10a4cb727e5ce0c7fd48d44fb683bf93b2fa7c59643148748496b286b0?"
              class="img-3"
            />
          </div>
        </div>
      </div>
    </div> -->
    <div class="div-8">
      <div class="div-9">
        <img height="100%" width="60px" src="../../../assets/images/avtar/Group 3727.png">
      </div>
      <div class="div-18">
        <div class="div-19">Question 4 of 4</div>
        <div class="div-20">Whats your main reason for using Imparter ?</div>
        <div class="div-21">
          Your answer will help us personalize your experience
        </div>
        <!-- <div class="div-22">Scheduling and publishing</div>
        <div class="div-23">Collaborating with creators and brands</div>
        <div class="div-24">I dont know- I”m Just trying this out</div> -->


        <div class="selectable div-22" [ngClass]="{ 'selected': selectedDiv === 'div-22' }" (click)="selectDiv('div-22')">Scheduling and publishing</div>
        <div class="selectable div-23" [ngClass]="{ 'selected': selectedDiv === 'div-23' }" (click)="selectDiv('div-23')">Collaborating with creators and brands</div>
        <div class="selectable div-24" [ngClass]="{ 'selected': selectedDiv === 'div-24' }" (click)="selectDiv('div-24')">I don't know- I'm just trying this out</div>
        

        <div class="div-25" (click)="userIntrest()">
          <div class="div-26">OK</div>
          <img src="../../../assets/icon/arrow-right.png">
        </div>
       
      </div>
    </div>
  </div>