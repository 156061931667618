import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrandModel } from '../models/brand-model';
const baseUrl=`${environment.baseUrl}`;

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(
    private http:HttpClient
  ) { }

  async insertBrand(formData:FormData){
  
  let token ='Bearer '+await localStorage.getItem('token');
  let headers=new HttpHeaders({
    'Authorization':token
  });

  const options={
    headers: headers,
  };

  return this.http.post(`${baseUrl}/Brand/InsertBrand`,formData,options)
  }
  
  async getBrand(user_id:any){
    let token='Bearer '+await localStorage.getItem('token');
    let headers=new HttpHeaders({
      'Authorization':token
    });
    const options={
      headers:headers,
    }
    return this.http.get(`${baseUrl}/Brand/GetAllBrand?user_id=`+user_id,options)
  }

}
