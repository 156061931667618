export class InstagramUserInfo {

    socialMediaUserId: any;
    instaIgId:any
    pageId: any;
    pageName: any;
    pageCategory: any;
    accessToken: any;
    userUId: any
    orgUId: any
    brandId: any
    cuurentstatus: any
    loginStatus: any
    createdBy:any
    instaIgName: any
    instaIgUserName:any
    social_Media_Keyword:any
    profileImageLink:any

}
