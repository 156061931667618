<div class="container-fluid p-0">
  <div class="row">

    <div>
      <div class="login-card center">
        <div class="div">
          <div style="align-self: center; width: 554px;max-width: 100%; align-items: start;
                 justify-content: space-between;margin-top: 50px;">
            <div class="p-2">
              Congratulations on joining Imparter!
              <br>
              Begin your journey of discovery and tap into the vast opportunities
              our platform offers for your learning and personal growth.
            </div>
          </div>
          <img loading="lazy" src="../../../assets/images/Letgetstarted.png" class="img-4" />
          <div class="div-8">
            <div class="div-9">
              <div class="div-11">Let's get Started.....</div>
            </div>
           <div style="margin-left: -25px; cursor: pointer;" (click)="routtonext()">
            <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/14302b9753c10764fc011c6793e51a0f9f458ff338a6b4bba553b6f046ba43ef?"
              class="img-5" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div style="text-align: center;">
  <div class="card p-2">
    <p>
      Congratulations on joining Imparter!
      <br>
      Begin your journey of discovery and tap into the vast opportunities
      our platform offers for your learning and personal growth.
    </p>
    </div>
</div> -->




