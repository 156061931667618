<div class="container-fluid">
  <div class="row">
    <!-- <div class="col-xl-7" [ngStyle]="{'background-image': 'url(assets/images/login/2.jpg)',
                                     'background-size': 'cover',
                                     'background-position': 'center center',
                                     'display': 'block'}">
      <img class="bg-img-cover bg-center d-none" src="assets/images/login/2.jpg" alt="looginpage">
    </div> -->
    <div>
      <div class="login-card">
        <div>
          <div>
            <a class="logo text-center" >

              <img class="img-fluid for-light" height="40px" width="180px"
                src="../../../assets/images/Impartr_Logo_light.png" alt="looginpage">
              <img class="img-fluid for-dark" height="40px" width="180px"
                src="../../../assets/images/Impartr_Logo_dark.png" alt="looginpage">
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form needs-validation" [class.was-validated]="!loginForm.valid" novalidate=""
              [formGroup]="loginForm">
              <h4 style="text-align: center;">Sign in to account</h4>
              <p style="text-align: center;">Enter your email & password to login</p>

              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <!-- <input class="form-control" type="email" required="" placeholder="app@yourmail.com"
                  formControlName="email"> -->
                <input  class="form-control" type="email" required="" placeholder="app@yourmail.com" formControlName="email">
                <div  class="invalid-feedback"
                  *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required">Email is
                  required.</div>
                <div class="invalid-feedback"
                  *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email">Invalid Email
                  Address.</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input  class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required=""
                  placeholder="*********" formControlName="password">

                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
              </div>

              <div class="invalid-feedback"
                *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">Password
                is required.</div>

              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox">
                  <label for="checkbox1">Remember password</label>
                </div>
                <a (click)="forgotPass()" class="link">Forgot password?</a>
                <button id="submit-button" class="btn btn-primary d-block w-100" type="button" (click)="login()">Sign in</button>
              </div>
              <h6 class="mt-4 or">Or Sign in with</h6>
              <!-- <div class="social mt-4">
                <div class="btn-showcase">
                  <div id="buttonDiv"></div>
                  <a class="btn btn-light" (click)="loginWithFacebook()" target="_blank">
                    <img src="../../../assets/images/Facebook.png" height="18px"> facebook
                  </a>
                </div>
              </div> -->
              <div class="social mt-4">
                <div class="btn-showcase">
                  <a class="btn text-dark" style="background-color: #EEF6FF;" target="_blank" (click)="googleSignIn()"> <app-feather-icons
                      [icon]="'log-in'" class="txt-google"></app-feather-icons>Google + </a>
                  <a class="btn text-dark" style="background-color: #EEF6FF;" target="_blank" (click)="loginWithFacebook()">
                     <img src="../../../assets/icon/Fb_icon.png" alt="" height="17px" srcset="">&nbsp;Facebook</a>
                  <a class="btn text-dark" style="background-color: #EEF6FF;" target="_blank" (click)="loginWithTwitter()"> <app-feather-icons [icon]="'twitter'"
                      class="txt-twitter"></app-feather-icons>twitter </a>
                </div>
              </div>
              <p class="mt-4 mb-0">Don't have account?
                <a [routerLink]="'/auth/sign-up'" class="ms-2">Create Account</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="login-main">
    <form class="theme-form needs-validation" [class.was-validated]="!loginForm.valid" novalidate=""
      [formGroup]="loginForm">
      <h4>Sign in to account</h4>
      <p>Enter your email & password to login</p>
      <div class="form-group">
        <label class="col-form-label">Email Address</label>
        <input class="form-control" type="email" required="" placeholder="app@yourmail.com" formControlName="email">
        <div class="invalid-feedback"
          *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required">Email is
          required.</div>
        <div class="invalid-feedback"
          *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email">Invalid Email
          Address.</div>
      </div>
      <div class="form-group">
        <label class="col-form-label">Password</label>
        <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required=""
          placeholder="*********" formControlName="password">

        <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
        <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
      </div>

      <div class="invalid-feedback"
        *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">Password
        is required.</div>

      <div class="form-group mb-0">
        <div class="checkbox p-0">
          <input id="checkbox1" type="checkbox">
          <label for="checkbox1">Remember password</label>
        </div>
        <a [routerLink]="'/auth/forgot-password'" class="link">Forgot password?</a>
        <button class="btn btn-primary d-block w-100" type="button" (click)="login()">Sign in</button>
      </div>
      <h6 class="mt-4 or">Or Sign in with</h6>

      <div class="social mt-4">
        <div class="btn-showcase">
          <a class="btn btn-light" target="_blank" (click)="googleSignIn()"> <app-feather-icons [icon]="'log-in'"
              class="txt-google"></app-feather-icons>Google + </a>
          <a class="btn btn-light" target="_blank" (click)="loginWithFacebook()"> <app-feather-icons [icon]="'facebook'"
              class="txt-fb"></app-feather-icons>Facebook </a>
          <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'twitter'"
              class="txt-twitter"></app-feather-icons>twitter </a>
        </div>
      </div>
      <p class="mt-4 mb-0">Don't have account?
        <a [routerLink]="'/auth/sign-up'" class="ms-2">Create Account</a>
      </p>
    </form>
  </div> -->
</div>
