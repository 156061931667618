import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SearchService } from 'src/app/services/search.service';
import Swal from 'sweetalert2'


export class update {
  userMobileNumber:any;
  email:any;
  firstName:any;
  lastName:any;
  uid:any;
  company_Name:any;
  company_Website:any;
  address:any;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;
  @Input() subTitle : string;

  userDetail!: FormGroup;
  profileImageUrl: string = ''; // Store the URL of the profile image
  isModalOpen: boolean = false;
  profileImageFile!: File | null;
  emailid: any;
  firstname: any;
  lastname: any;
  mobileNum: any;
  company: any;
  update : update = new update()
  constructor(private dashboard: DashboardService,
    private searchService: SearchService,
    private formBuilder: FormBuilder,private router:Router) {
  }

  ngOnInit() {  }
  formInit1(){
    this.userDetail = this.formBuilder.group({
      email: [''],
      firstName: [''],
      lastName: [''],
      mobile: [''],
      companyName: [''],
      website: [''],
      address: [''],  
      profileImage:['']    
    });
  }

  async getProfile(){
    (await this.dashboard.getdetails()).subscribe((data:any)=>{
      console.log(data);
      if(data['StatusCode']=== 200){
        console.log(data['Response']);
        const userDetails = data['Response'];
        this.profileImageUrl = userDetails.profieImage;
        this.userDetail.patchValue({
          email: userDetails.email,
          firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            mobile: userDetails.userMobileNumber,
            companyName: userDetails.company_Name,
            website: userDetails.company_Website,
            address: userDetails.address
        });
        this.firstname =  userDetails.firstName;
        this.userDetail.get('email').disable();
      }
      
    })
  }

logout(){
  localStorage.clear();
  // this.router.navigateByUrl('/sign-in');
}
createPost(){
  // this.router.navigateByUrl('/create-post')
  this.router.navigateByUrl('/create-post-instagram')
  
}


}
