import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// const baseUrl = `${environment.baseUrl}`;

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseUrl =  environment.baseUrl;
  constructor(private http:HttpClient) { }

  async getData(userId:any){
    // let token='Bearer '+ await localStorage.getItem('token')

    let token='Bearer '+ await localStorage.getItem('token')
    console.log('token',token)
    let headers=new HttpHeaders({
      'Authorization':token
    });
    let options={
      headers:headers
    };
    return this.http.get(this.baseUrl +'/Facebook/GetAddFeed?User_uid='+ userId,options)
   }

   getDataNew(userId:any,brandId:any){
    let token='Bearer '+localStorage.getItem('token')
    console.log('token',token)
    let headers=new HttpHeaders({
      'Authorization':token
    });
    let options={
      headers:headers
    };
    console.log('userId in dashboard service--->',userId,'brand id--->',brandId);
    
    return this.http.get(this.baseUrl +'/Facebook/GetAddFeedByUserUidBrandId?userUid='+userId+'&brandId='+brandId,options)
   }

   async getdetails(){
    const uid= localStorage.getItem('userId')
    let token='Bearer '+ await localStorage.getItem('token')
    console.log('token',token)
    let headers=new HttpHeaders({
      'Authorization':token
    });
    let options={
      headers:headers
    };
    return this.http.get(this.baseUrl +'/UserRegistration/GetRegistrationByUid?uid='+ uid,options)
   }

   async updateDetails(formData:FormData){
    let token='Bearer '+await localStorage.getItem('token')
    console.log('token',token)
    let headers=new HttpHeaders({
      'Authorization':token
    });
    let options={
      headers:headers
    };
    return this.http.put(this.baseUrl +'/UserRegistration/UpdateUseregistration',formData,options)
   }

   async updateDetail(data:any){
    let token='Bearer '+ await localStorage.getItem('token')
    console.log('token',token)
    let headers=new HttpHeaders({
      'Authorization':token
    });
    let options={
      headers:headers
    };

    return this.http.put(this.baseUrl +'/UserRegistration/UpdateUseregistration',data,options)
   }


   async updatePwd(formData:FormData){
    let token='Bearer '+await localStorage.getItem('token')

    console.log('token',token)
    let headers=new HttpHeaders({
      'Authorization':token
    });
    let options={
      headers:headers
    };

    return this.http.put(this.baseUrl +'/UserRegistration/UpdatePassword',formData,options)
   }

   async connectSocialMedia(userId:any){
    let token='Bearer '+await localStorage.getItem('token');
    let headers=new HttpHeaders({
      'Authorization':token
    });
    let options={
      headers:headers
    };
    return this.http.get(this.baseUrl+'/Facebook/CheckSocialMedia_pages?user_Uid='+userId,options)
   }

}
