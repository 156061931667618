<div class="container-fluid">
    <div>
        <div class="login-card">
            <div>
                <div class="login-main1">
                    <form class="theme-form">
                        <h5 style="text-align: center;">Welcome to
                            <img class="img-fluid for-light " height="20px" width="130px"margin-top="2%"
                            src="../../../assets/images/Impartr_Logo_light.png" alt="looginpage" style="margin-top: -17px;">
                        <img class="img-fluid for-dark" height="20px" width="130px"
                            src="../../../assets/images/Impartr_Logo_dark.pngX" alt="looginpage" style="margin-top: -17px;">
                   
                        </h5>
                        <br>
                        <h5 style="text-align: center;">Connect Your Social Networks to Manage</h5>
                        <p style="text-align: center;font-size: smaller;">To get started please login by connecting your
                            social accounts.</p>
                        <div class="form-group">
                            <div class="breadcrumb breadcrumb-colored m-b-10 bg-custom-color d-flex align-items-center">
                                <img src="../../../assets/icon/Facebook_Logo1_2023.png" height="30px">
                                <span class="flex-grow-1 p-1">&nbsp;&nbsp;Facebook pages</span>
                                <button class="btn btn-secondary btn-sm" type="button"
                                    (click)="connectWithFacebook()">Connect</button>
                            </div>
                            <div class="breadcrumb breadcrumb-colored m-b-10 bg-custom-color d-flex align-items-center">
                                <img src="../../../assets/icon/Instagram_logo1.png" height="30px">
                                <span class="flex-grow-1 p-1">&nbsp;&nbsp;Instagram pages</span>
                                <button class="btn btn-secondary btn-sm" type="button"
                                    (click)="connectWithInstagram()">Connect</button>
                            </div>
                            <div class="breadcrumb breadcrumb-colored m-b-10 bg-custom-color d-flex align-items-center">
                                <img src="../../../assets/icon/Twitter_Logo.png" height="30px">
                                <span class="flex-grow-1 p-1">&nbsp;&nbsp;Twitter pages</span>
                                <button class="btn btn-secondary btn-sm" type="button">Connect</button>
                            </div>
                        </div>
                        <br>
                        <div class="form-group">
                            <div class="d-flex justify-content-center mt-4">
                                <button class="btn btn-primary mr-2" type="button" (click)="book_demo()">Book a Demo</button>
                                <button class="btn btn-primary ml-2" type="button"(click)=skip_explore()>Skip & Explore</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>