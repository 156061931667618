export class TwitterModel {

    oauthToken: any
    oauthVerifier: any
    userId: any
    orgId: any
    brandId: any
    socialMediaKeyword: any
    oAuthTokenResponse: any
    oAuthTokenSecret: any
    twitterUserName: any
    twitterUserId: any

}