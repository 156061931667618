<div class="div">

  <div class="div-8">
    <div class="div-9">
      <div class="div-10">
    
        <img height="100%" width="60px" src="../../../assets/images/avtar/Group 3717.png">
      </div>
      <div class="div-16">
        <div class="div-17">Question 1 of 4</div>
        <div class="div-18">
          Hi {{firstName}} which best describes your role ?
        </div>
        <div style="color: #757575;
        text-align: left;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;margin-top: 10px;">Your answer will help us personalize your experience
        </div>

        
        <div class="div-19">
          <div class="div-20">
            <div  *ngIf="imageSocialMediaManager===false" (click)="onChangeImage(1)">
              <div class="column" style="padding-bottom: 20px;">
                <div class="option" style="margin-left: 25px;">
                  <div class="div-21">
                    <img loading="lazy" src="../../../assets/images/avtar/social_media.png" class="img-5" />
                    <div class="div-22">
                      Social Media Manager
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  *ngIf="imageSocialMediaManager===true" (click)="onChangeImage(2)">
              <div class="column" style="padding-bottom: 20px;">
                <div class="option" style="background-color: #3B5880;text-align: center;margin-left: 25px;">
                  <div class="div-21" >
                    <img loading="lazy"  src="../../../assets/images/avtar/social_media.png" class="img-5" />
                    <div class="div-22" style="color: #fff;">
                      Social Media Manager
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  *ngIf="imageSmallBusinessOwner===false" (click)="onChangeImage(3)">
              <div class="column-2" style="padding-bottom: 20px;">
                <div class="option" style="margin-left: -1px;">
                  <div class="div-23">
                    <img loading="lazy" src="../../../assets/images/avtar/small_bussines.png" class="sm-buisness-img" />
                    <div class="div-22">
                      Small Business Owner
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  *ngIf="imageSmallBusinessOwner===true" (click)="onChangeImage(4)">
              <div class="column" style="margin-left: 19px;padding-bottom: 20px;">
                <div class="option" style="background-color: #3B5880;text-align: center;">
                  <div class="div-23" >
                    <img loading="lazy"   src="../../../assets/images/avtar/small_bussines.png" class="sm-buisness-img" />
                    <div class="div-22" style="color: #fff;">
                      Small Business Owner
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  *ngIf="imageCreator===false" (click)="onChangeImage(5)">
              <div class="column-3" style="padding-bottom: 20px;">
                <div class="option">
                  <div class="div-25">
                    <img loading="lazy" src="../../../assets/images/avtar/creator.png" class="img-6" />
                    <div class="div-22">Creator</div>
                  </div>
                </div>
              </div>
            </div>
            <div  *ngIf="imageCreator===true" (click)="onChangeImage(6)">
              <div class="column-3" style="padding-bottom: 20px;">
                <div class="option" style="background-color: #3B5880;text-align: center;">
                  <div class="div-25" >
                    <img loading="lazy" src="../../../assets/images/avtar/creator.png" class="img-6" />
                    <div class="div-22" style="color: #fff;">Creator</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="div-27">
          <div class="div-28">
            <div *ngIf="imageMarketingAgency===false" (click)="onChangeImage(7)">
              <div class="column" style="padding-bottom: 20px;">
                <div class="option" style="margin-left: 25px;">
                  <div class="div-29">
                    <img loading="lazy" src="../../../assets/images/avtar/marketing_agency.png" class="img-7" />
                    <div class="div-22">
                      Marketing Agency
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="imageMarketingAgency===true" (click)="onChangeImage(8)">
              <div class="column" style="padding-bottom: 20px;">
                <div class="option" style="background-color: #3B5880;text-align: center;margin-left: 25px;">
                  <div class="div-29" >
                    <img loading="lazy" src="../../../assets/images/avtar/marketing_agency.png" class="img-7" />
                    <div class="div-22" style="color: #fff;">
                      Marketing Agency
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="imageFreelancer===false" (click)="onChangeImage(9)">
              <div class="column-4" style="padding-bottom: 20px;">
                <div class="option">
                  <div class="div-31">
                    <img loading="lazy" src="../../../assets/images/avtar/freelancer.png" class="img-8" />
                    <div class="div-22">Freelancer</div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="imageFreelancer===true" (click)="onChangeImage(10)">
              <div class="column-4" style="padding-bottom: 20px;">
                <div class="option" style="background-color: #3B5880;text-align: center;">
                  <div class="div-31" >
                    <img loading="lazy" src="../../../assets/images/avtar/freelancer.png" class="img-8" />
                    <div class="div-22" style="color: #fff;">
                      Freelancer
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="imageOther===false" (click)="onChangeImage(11)">
              <div class="column-4" style="padding-bottom: 20px;">
                <div class="option">
                  <div class="div-31">
                    <img loading="lazy" src="../../../assets/images/avtar/other.png" class="img-8" />
                    <div class="div-22">Other
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="imageOther===true" (click)="onChangeImage(12)">
              <div class="column-4" style="padding-bottom: 20px;">
                <div class="option" style="background-color: #3B5880;text-align: center;">
                  <div class="div-25">
                    <img loading="lazy" src="../../../assets/images/avtar/other.png" class="other-img" />
                    <div class="div-34" style="color: #fff;">
                      Other
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="div-35" (click)="routeToQue2()" [ngClass]="{'responsive-div': isResponsive}">
      <div class="div-36">Next</div>  
      <img src="../../../assets/icon/arrow-right.png">
    </div>
  </div>
</div>
