import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NavService } from 'src/app/shared/services/nav.service';
import { VerifyOtp } from 'src/app/models/verify-otp';
import { RegistrationModel } from 'src/app/models/registration-model';
import { BrandService } from 'src/app/services/brand.service';
import { BrandModel } from 'src/app/models/brand-model';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-create-brand',
  templateUrl: './create-brand.component.html',
  styleUrl: './create-brand.component.scss'
})
export class CreateBrandComponent implements OnInit {
public show: boolean = false;
  countries: any[] | undefined;
  signin!: FormGroup;
  inputValue: any;
  selectedCountry: any;
  brandName:any;
  myForm!: FormGroup;

email:any;
  countryCode: any;
  countryCode1:any;
  mobileCode: any;
  country: any;
  userData1: any;
  password: any;
  alert: any;
  url: any;
  selectedCode: any;
  uid: any;
  userData2: any;
  token: any;
  token1: any;
  public loading = false;
  country1: any;
  public language: boolean = false;
  brandModelList:BrandModel=new BrandModel();
  timeZone:any;

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
  {
    language: 'Español',
    code: 'es',
    icon: 'es'
  },
  {
    language: 'Français',
    code: 'fr',
    icon: 'fr'
  },
  {
    language: 'Português',
    code: 'pt',
    type: 'BR',
    icon: 'pt'
  }]

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }
  currentTime: any;


  changeLanguage(lang) {

    this.selectedLanguage = lang;
  }


  public config = {
    settings: {
      layout: 'Dubai',
      layout_type: 'ltr',
      layout_version: 'light-only',
      sidebar_type: 'default-sidebar',
      icon: 'stroke-svg'
    },
    color: {
      primary_color: '#3a5880', 
      secondary_color: '#7ea3d0'
    }
  }


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private brandService:BrandService,
    public navServices: NavService,
  ) {
    if(this.config.settings.layout_type == 'rtl')
    document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);
    document.documentElement.style.setProperty('--theme-deafult', this.config.color.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.config.color.secondary_color);

  }

  ngOnInit() {
    this.formInit()

    this.countries = [
      { code: '93', shortName: 'AF', name: 'Afghanistan', timeZone: 'Asia/Kabul' },
      { code: '358', shortName: 'AX', name: 'Aland Islands', timeZone: 'Europe/Mariehamn' },
      { code: '355', shortName: 'AL', name: 'Albania', timeZone: 'Europe/Tirane' },
      { code: '213', shortName: 'DZ', name: 'Algeria', timeZone: 'Africa/Algiers' },
      { code: '1', shortName: 'AS', name: 'American Samoa', timeZone: 'Pacific/Pago_Pago' },
      { code: '376', shortName: 'AD', name: 'Andorra', timeZone: 'Europe/Andorra' },
      { code: '244', shortName: 'AO', name: 'Angola', timeZone: 'Africa/Luanda' },
      { code: '1', shortName: 'AI', name: 'Anguilla', timeZone: 'America/Anguilla' },
      { code: '672', shortName: 'AQ', name: 'Antarctica', timeZone: 'Antarctica/Casey' },
      { code: '1', shortName: 'AG', name: 'Antigua and Barbuda', timeZone: 'America/Antigua' },
    
      
        { code: '54', shortName: 'AR', name: 'Argentina', timeZone: 'America/Argentina/Buenos_Aires' },
        { code: '374', shortName: 'AM', name: 'Armenia', timeZone: 'Asia/Yerevan' },
        { code: '297', shortName: 'AW', name: 'Aruba', timeZone: 'America/Aruba' },
        { code: '61', shortName: 'AU', name: 'Australia', timeZone: 'Australia/Sydney' },
        { code: '43', shortName: 'AT', name: 'Austria', timeZone: 'Europe/Vienna' },
        { code: '994', shortName: 'AZ', name: 'Azerbaijan', timeZone: 'Asia/Baku' },
        { code: '1', shortName: 'BS', name: 'Bahamas', timeZone: 'America/Nassau' },
        { code: '973', shortName: 'BH', name: 'Bahrain', timeZone: 'Asia/Bahrain' },
        { code: '880', shortName: 'BD', name: 'Bangladesh', timeZone: 'Asia/Dhaka' },
        { code: '1', shortName: 'BB', name: 'Barbados', timeZone: 'America/Barbados' },
        { code: '375', shortName: 'BY', name: 'Belarus', timeZone: 'Europe/Minsk' },
        { code: '32', shortName: 'BE', name: 'Belgium', timeZone: 'Europe/Brussels' },
        { code: '501', shortName: 'BZ', name: 'Belize', timeZone: 'America/Belize' },
        { code: '229', shortName: 'BJ', name: 'Benin', timeZone: 'Africa/Porto-Novo' },
        { code: '1', shortName: 'BM', name: 'Bermuda', timeZone: 'Atlantic/Bermuda' },
        { code: '975', shortName: 'BT', name: 'Bhutan', timeZone: 'Asia/Thimphu' },
        { code: '591', shortName: 'BO', name: 'Bolivia', timeZone: 'America/La_Paz' },
        { code: '387', shortName: 'BA', name: 'Bosnia and Herzegovina', timeZone: 'Europe/Sarajevo' },
        { code: '267', shortName: 'BW', name: 'Botswana', timeZone: 'Africa/Gaborone' },
        { code: '55', shortName: 'BR', name: 'Brazil', timeZone: 'America/Sao_Paulo' },
        { code: '246', shortName: 'IO', name: 'British Indian Ocean Territory', timeZone: 'Indian/Chagos' },
        { code: '1', shortName: 'VG', name: 'British Virgin Islands', timeZone: 'America/Tortola' },
        { code: '673', shortName: 'BN', name: 'Brunei Darussalam', timeZone: 'Asia/Brunei' },
        { code: '359', shortName: 'BG', name: 'Bulgaria', timeZone: 'Europe/Sofia' },
        { code: '226', shortName: 'BF', name: 'Burkina Faso', timeZone: 'Africa/Ouagadougou' },
        { code: '257', shortName: 'BI', name: 'Burundi', timeZone: 'Africa/Bujumbura' },

     //C
     { code: '855', shortName: 'KH', name: 'Cambodia', timeZone: 'Asia/Phnom_Penh' },
     { code: '237', shortName: 'CM', name: 'Cameroon', timeZone: 'Africa/Douala' },
     { code: '1', shortName: 'CA', name: 'Canada', timeZone: 'America/Toronto' },
     { code: '238', shortName: 'CV', name: 'Cape Verde', timeZone: 'Atlantic/Cape_Verde' },
     { code: '1', shortName: 'KY', name: 'Cayman Islands', timeZone: 'America/Cayman' },
     { code: '236', shortName: 'CF', name: 'Central African Republic', timeZone: 'Africa/Bangui' },
     { code: '235', shortName: 'TD', name: 'Chad', timeZone: 'Africa/Ndjamena' },
     { code: '56', shortName: 'CL', name: 'Chile', timeZone: 'America/Santiago' },
     { code: '86', shortName: 'CN', name: 'China', timeZone: 'Asia/Shanghai' },
     { code: '61', shortName: 'CX', name: 'Christmas Island', timeZone: 'Indian/Christmas' },
     { code: '61', shortName: 'CC', name: 'Cocos (Keeling) Islands', timeZone: 'Indian/Cocos' },
     { code: '57', shortName: 'CO', name: 'Colombia', timeZone: 'America/Bogota' },
     { code: '269', shortName: 'KM', name: 'Comoros', timeZone: 'Indian/Comoro' },
     { code: '242', shortName: 'CG', name: 'Congo (Brazzaville)', timeZone: 'Africa/Brazzaville' },
     { code: '243', shortName: 'CD', name: 'Congo, (Kinshasa)', timeZone: 'Africa/Kinshasa' },
     { code: '682', shortName: 'CK', name: 'Cook Islands', timeZone: 'Pacific/Rarotonga' },
     { code: '506', shortName: 'CR', name: 'Costa Rica', timeZone: 'America/Costa_Rica' },
     { code: '225', shortName: 'CI', name: "Côte d'Ivoire", timeZone: 'Africa/Abidjan' },
     { code: '385', shortName: 'HR', name: 'Croatia', timeZone: 'Europe/Zagreb' },
     { code: '53', shortName: 'CU', name: 'Cuba', timeZone: 'America/Havana' },
     { code: '599', shortName: 'CW', name: 'Curacao', timeZone: 'America/Curacao' },
     { code: '357', shortName: 'CY', name: 'Cyprus', timeZone: 'Asia/Nicosia' },
     { code: '420', shortName: 'CZ', name: 'Czech Republic', timeZone: 'Europe/Prague' },
  //D
  { code: '45', shortName: 'DK', name: 'Denmark', timeZone: 'Europe/Copenhagen' },
  { code: '253', shortName: 'DJ', name: 'Djibouti', timeZone: 'Africa/Djibouti' },
  { code: '1', shortName: 'DM', name: 'Dominica', timeZone: 'America/Dominica' },
  { code: '1', shortName: 'DO', name: 'Dominican Republic', timeZone: 'America/Santo_Domingo' },

      //E
      { code: '593', shortName: 'EC', name: 'Ecuador', timeZone: 'America/Guayaquil' },
      { code: '20', shortName: 'EG', name: 'Egypt', timeZone: 'Africa/Cairo' },
      { code: '503', shortName: 'SV', name: 'El Salvador', timeZone: 'America/El_Salvador' },
      { code: '240', shortName: 'GQ', name: 'Equatorial Guinea', timeZone: 'Africa/Malabo' },
      { code: '291', shortName: 'ER', name: 'Eritrea', timeZone: 'Africa/Asmara' },
      { code: '372', shortName: 'EE', name: 'Estonia', timeZone: 'Europe/Tallinn' },
      { code: '251', shortName: 'ET', name: 'Ethiopia', timeZone: 'Africa/Addis_Ababa' },
   //F
   { code: '500', shortName: 'FK', name: 'Falkland Islands (Malvinas)', timeZone: 'Atlantic/Stanley' },
   { code: '298', shortName: 'FO', name: 'Faroe Islands', timeZone: 'Atlantic/Faroe' },
   { code: '679', shortName: 'FJ', name: 'Fiji', timeZone: 'Pacific/Fiji' },
   { code: '358', shortName: 'FI', name: 'Finland', timeZone: 'Europe/Helsinki' },
   { code: '33', shortName: 'FR', name: 'France', timeZone: 'Europe/Paris' },
   { code: '594', shortName: 'GF', name: 'French Guiana', timeZone: 'America/Cayenne' },
   { code: '689', shortName: 'PF', name: 'French Polynesia', timeZone: 'Pacific/Tahiti' },

      //G
      { code: '241', shortName: 'GA', name: 'Gabon', timeZone: 'Africa/Libreville' },
      { code: '220', shortName: 'GM', name: 'Gambia', timeZone: 'Africa/Banjul' },
      { code: '995', shortName: 'GE', name: 'Georgia', timeZone: 'Asia/Tbilisi' },
      { code: '49', shortName: 'DE', name: 'Germany', timeZone: 'Europe/Berlin' },
      { code: '233', shortName: 'GH', name: 'Ghana', timeZone: 'Africa/Accra' },
      { code: '350', shortName: 'GI', name: 'Gibraltar', timeZone: 'Europe/Gibraltar' },
      { code: '30', shortName: 'GR', name: 'Greece', timeZone: 'Europe/Athens' },
      { code: '299', shortName: 'GL', name: 'Greenland', timeZone: 'America/Godthab' },
      { code: '1', shortName: 'GD', name: 'Grenada', timeZone: 'America/Grenada' },
      { code: '590', shortName: 'GP', name: 'Guadeloupe', timeZone: 'America/Guadeloupe' },
      { code: '1', shortName: 'GU', name: 'Guam', timeZone: 'Pacific/Guam' },
      { code: '502', shortName: 'GT', name: 'Guatemala', timeZone: 'America/Guatemala' },
      { code: '44', shortName: 'GG', name: 'Guernsey', timeZone: 'Europe/Guernsey' },
      { code: '224', shortName: 'GN', name: 'Guinea', timeZone: 'Africa/Conakry' },
      { code: '245', shortName: 'GW', name: 'Guinea-Bissau', timeZone: 'Africa/Bissau' },
      { code: '592', shortName: 'GY', name: 'Guyana', timeZone: 'America/Guyana' },
  
      //H
      { code: '509', shortName: 'HT', name: 'Haiti', timeZone: 'America/Port-au-Prince' },
      { code: '379', shortName: 'VA', name: 'Holy See (Vatican City State)', timeZone: 'Europe/Vatican' },
      { code: '504', shortName: 'HN', name: 'Honduras', timeZone: 'America/Tegucigalpa' },
      { code: '852', shortName: 'HK', name: 'Hong Kong', timeZone: 'Asia/Hong_Kong' },
      { code: '36', shortName: 'HU', name: 'Hungary', timeZone: 'Europe/Budapest' },
  
      //I
      { code: '354', shortName: 'IS', name: 'Iceland', timeZone: 'Atlantic/Reykjavik' },
    { code: '91', shortName: 'IN', name: 'India', timeZone: 'Asia/Kolkata' },
    { code: '62', shortName: 'ID', name: 'Indonesia', timeZone: 'Asia/Jakarta' },
    { code: '98', shortName: 'IR', name: 'Iran, Islamic Republic of', timeZone: 'Asia/Tehran' },
    { code: '964', shortName: 'IQ', name: 'Iraq', timeZone: 'Asia/Baghdad' },
    { code: '353', shortName: 'IE', name: 'Ireland', timeZone: 'Europe/Dublin' },
    { code: '44', shortName: 'IM', name: 'Isle of Man', timeZone: 'Europe/Isle_of_Man' },
    { code: '972', shortName: 'IL', name: 'Israel', timeZone: 'Asia/Jerusalem' },
    { code: '39', shortName: 'IT', name: 'Italy', timeZone: 'Europe/Rome' },

      //J
      { code: '1', shortName: 'JM', name: 'Jamaica', timeZone: 'America/Jamaica' },
    { code: '81', shortName: 'JP', name: 'Japan', timeZone: 'Asia/Tokyo' },
    { code: '44', shortName: 'JE', name: 'Jersey', timeZone: 'Europe/Jersey' },
    { code: '962', shortName: 'JO', name: 'Jordan', timeZone: 'Asia/Amman' },
//k
    { code: '7', shortName: 'KZ', name: 'Kazakhstan', timeZone: 'Asia/Almaty' },
    { code: '254', shortName: 'KE', name: 'Kenya', timeZone: 'Africa/Nairobi' },
    { code: '686', shortName: 'KI', name: 'Kiribati', timeZone: 'Pacific/Tarawa' },
    { code: '383', shortName: 'XK', name: 'Kosova', timeZone: 'Europe/Belgrade' },
    { code: '965', shortName: 'KW', name: 'Kuwait', timeZone: 'Asia/Kuwait' },
    { code: '996', shortName: 'KG', name: 'Kyrgyzstan', timeZone: 'Asia/Bishkek' },
    //l
    { code: '856', shortName: 'LA', name: 'Lao PDR', timeZone: 'Asia/Vientiane' },
    { code: '371', shortName: 'LV', name: 'Latvia', timeZone: 'Europe/Riga' },
    { code: '961', shortName: 'LB', name: 'Lebanon', timeZone: 'Asia/Beirut' },
    { code: '266', shortName: 'LS', name: 'Lesotho', timeZone: 'Africa/Maseru' },
    { code: '231', shortName: 'LR', name: 'Liberia', timeZone: 'Africa/Monrovia' },
    { code: '218', shortName: 'LY', name: 'Libya', timeZone: 'Africa/Tripoli' },
    { code: '423', shortName: 'LI', name: 'Liechtenstein', timeZone: 'Europe/Vaduz' },
    { code: '370', shortName: 'LT', name: 'Lithuania', timeZone: 'Europe/Vilnius' },
    { code: '356', shortName: 'LU', name: 'Luxembourg', timeZone: 'Europe/Luxembourg' },
    //m
    { code: '853', shortName: 'MO', name: 'Macau', timeZone: 'Asia/Macau' },
    { code: '389', shortName: 'MK', name: 'Macedonia', timeZone: 'Europe/Skopje' },
    { code: '261', shortName: 'MG', name: 'Madagascar', timeZone: 'Indian/Antananarivo' },
    { code: '265', shortName: 'MW', name: 'Malawi', timeZone: 'Africa/Blantyre' },
    { code: '60', shortName: 'MY', name: 'Malaysia', timeZone: 'Asia/Kuala_Lumpur' },
    { code: '960', shortName: 'MV', name: 'Maldives', timeZone: 'Indian/Maldives' },

  { code: '223', shortName: 'ML', name: 'Mali', flagUrl: "../../../assets/img/Flag_of_Mali.png", timeZone: "Africa/Bamako" },
  { code: '356', shortName: 'MT', name: 'Malta', flagUrl: "../../../assets/img/Flag_of_Malta.png", timeZone: "Europe/Malta" },
  { code: '692', shortName: 'MH', name: 'Marshall Islands', flagUrl: "../../../assets/img/Flag_of_the_Marshall_Islands.png", timeZone: "Pacific/Majuro" },
  { code: '596', shortName: 'MQ', name: 'Martinique', flagUrl: "../../../assets/img/Snake_Flag_of_Martinique.png", timeZone: "America/Martinique" },
  { code: '222', shortName: 'MR', name: 'Mauritania', flagUrl: "../../../assets/img/Flag_of_Mauritania.png", timeZone: "Africa/Nouakchott" },
  { code: '230', shortName: 'MU', name: 'Mauritius', flagUrl: "../../../assets/img/Flag_of_Mauritius.png", timeZone: "Indian/Mauritius" },
  { code: '262', shortName: 'YT', name: 'Mayotte', flagUrl: "../../../assets/img/Flag-of-Mayotte.png", timeZone: "Indian/Mayotte" },
  { code: '52', shortName: 'MX', name: 'Mexico', flagUrl: "../../../assets/img/Flag_of_Mexico.png", timeZone: "America/Mexico_City" },
  { code: '691', shortName: 'FM', name: 'Micronesia,Federated States of', flagUrl: "../../../assets/img/Flag_of_the_Federated_States_of_Micronesia.png", timeZone: "Pacific/Chuuk" },
  { code: '373', shortName: 'MD', name: 'Moldova', flagUrl: "../../../assets/img/Flag_of_Moldova.png", timeZone: "Europe/Chisinau" },
  { code: '377', shortName: 'MC', name: 'Monaco', flagUrl: "../../../assets/img/Flag_of_Monaco.png", timeZone: "Europe/Monaco" },
  { code: '976', shortName: 'MN', name: 'Mongolia', flagUrl: "../../../assets/img/Flag_of_Mongolia.png", timeZone: "Asia/Ulaanbaatar" },
  { code: '382', shortName: 'ME', name: 'Montenegro', flagUrl: "../../../assets/img/Flag_of_Montenegro.png", timeZone: "Europe/Podgorica" },
  { code: '1', shortName: 'MS', name: 'Montserrat', flagUrl: "../../../assets/img/Montserrat_flag.png", timeZone: "America/Montserrat" },
  { code: '212', shortName: 'MA', name: 'Morocco', flagUrl: "../../../assets/img/Flag_of_Morocco.png", timeZone: "Africa/Casablanca" },
  { code: '258', shortName: 'MZ', name: 'Mozambique', flagUrl: "../../../assets/img/Flag_of_Mozambique.png", timeZone: "Africa/Maputo" },
  { code: '95', shortName: 'MM', name: 'Myanmar', flagUrl: "../../../assets/img/Flag_of_Myanmar.png", timeZone: "Asia/Yangon" },
  { code: '264', shortName: 'NA', name: 'Namibia', flagUrl: "../../../assets/img/Flag_of_Namibia.png", timeZone: "Africa/Windhoek" },
  { code: '674', shortName: 'NR', name: 'Nauru', flagUrl: "../../../assets/img/Flag_of_Nauru.png", timeZone: "Pacific/Nauru" },
  { code: '977', shortName: 'NP', name: 'Nepal', flagUrl: "../../../assets/img/Flag_of_Nepal.png", timeZone: "Asia/Kathmandu" },
  { code: '31', shortName: 'NL', name: 'Netherlands', flagUrl: "../../../assets/img/Flag_of_the_Netherlands.png", timeZone: "Europe/Amsterdam" },
  { code: '687', shortName: 'NC', name: 'New Caledonia', flagUrl: "../../../assets/img/Flag_of_New Caledonia.png", timeZone: "Pacific/Noumea" },
  { code: '64', shortName: 'NZ', name: 'New Zealand', flagUrl: "../../../assets/img/Flag_of_New_Zealand.png", timeZone: "Pacific/Auckland" },
  { code: '505', shortName: 'NI', name: 'Nicaragua', flagUrl: "../../../assets/img/Flag_of_Nicaragua.png", timeZone: "America/Managua" },
  { code: '227', shortName: 'NE', name: 'Niger', flagUrl: "../../../assets/img/Flag_of_Niger.png", timeZone: "Africa/Niamey" },
  { code: '234', shortName: 'NG', name: 'Nigeria', flagUrl: "../../../assets/img/Flag_of_Nigeria.jpeg", timeZone: "Africa/Lagos" },
  { code: '683', shortName: 'NU', name: 'Niue', flagUrl: "../../../assets/img/Flag_of_Niue.png", timeZone: "Pacific/Niue" },
  { code: '672', shortName: 'NF', name: 'Norfolk Island', flagUrl: "../../../assets/img/Flag_of_Norfolk_Island.png", timeZone: "Pacific/Norfolk" },
  { code: '850', shortName: 'KP', name: 'North Korea', flagUrl: "../../../assets/img/Flag_of_North_Korea.png", timeZone: "Asia/Pyongyang" },
  { code: '1', shortName: 'MP', name: 'Northern Mariana Islands', flagUrl: "../../../assets/img/Flag_of_the_Northern_Mariana_Islands.png", timeZone: "Pacific/Saipan" },
  { code: '47', shortName: 'NO', name: 'Norway', flagUrl: "../../../assets/img/Flag_of_Norway.png", timeZone: "Europe/Oslo" },
  { code: '968', shortName: 'OM', name: 'Oman', flagUrl: "../../../assets/img/Flag_of_Oman.png", timeZone: "Asia/Muscat" },
  { code: '92', shortName: 'PK', name: 'Pakistan', flagUrl: "../../../assets/img/Flag_of_Pakistan.png", timeZone: "Asia/Karachi" },
  { code: '680', shortName: 'PW', name: 'Palau', flagUrl: "../../../assets/img/Flag_of_Palau.png", timeZone: "Pacific/Palau" },
  { code: '970', shortName: 'PS', name: 'Palestinian Territory', flagUrl: "../../../assets/img/Flag_of_Palestinian_Territory.png", timeZone: "Asia/Gaza" },
  { code: '507', shortName: 'PA', name: 'Panama', flagUrl: "../../../assets/img/Flag_of_Panama.jpeg", timeZone: "America/Panama" },
  { code: '675', shortName: 'PG', name: 'Papua New Guinea', flagUrl: "../../../assets/img/Flag_of_Papua_New_Guinea.png", timeZone: "Pacific/Port_Moresby" },
  { code: '595', shortName: 'PY', name: 'Paraguay', flagUrl: "../../../assets/img/Paraguay_flag.png", timeZone: "America/Asuncion" },
  { code: '51', shortName: 'PE', name: 'Peru', flagUrl: "../../../assets/img/Flag_of_Peru.png", timeZone: "America/Lima" },
  { code: '63', shortName: 'PH', name: 'Philippines', flagUrl: "../../../assets/img/Flag_of_Philippines.png", timeZone: "Asia/Manila" },
  { code: '870', shortName: 'PN', name: 'Pitcairn', flagUrl: "../../../assets/img/Flag_of_the_Pitcairn_Islands.png", timeZone: "Pacific/Pitcairn" },
  { code: '48', shortName: 'PL', name: 'Poland', flagUrl: "../../../assets/img/Flag_of_Poland.png", timeZone: "Europe/Warsaw" },
  { code: '351', shortName: 'PT', name: 'Portugal', flagUrl: "../../../assets/img/Flag_of_Portugal.png", timeZone: "Europe/Lisbon" },
  { code: '1', shortName: 'PR', name: 'Puerto Rico', flagUrl: "../../../assets/img/Flag_of_Puerto_Rico.png", timeZone: "America/Puerto_Rico" },
  { code: '974', shortName: 'QA', name: 'Qatar', flagUrl: "../../../assets/img/Flag_of_Qatar.png", timeZone: "Asia/Qatar" },
  { code: '262', shortName: 'RE', name: 'Réunion', flagUrl: "../../../assets/img/Flag_of_Réunion.png", timeZone: "Indian/Reunion" },
  { code: '40', shortName: 'RO', name: 'Romania', flagUrl: "../../../assets/img/Flag_of_Romania.png", timeZone: "Europe/Bucharest" },
  { code: '7', shortName: 'RU', name: 'Russian Federation', flagUrl: "../../../assets/img/Flag_of_Russia.png", timeZone: "Europe/Moscow" },
  { code: '250', shortName: 'RW', name: 'Rwanda', flagUrl: "../../../assets/img/Flag_of_Rwanda.png", timeZone: "Africa/Kigali" },
  { code: '590', shortName: 'BL', name: 'Saint-Barthélemy', flagUrl: "../../../assets/img/Flag_of_Saint_Barthélemy.png", timeZone: "America/St_Barthelemy" },
  { code: '290', shortName: 'SH', name: 'Saint Helena', flagUrl: "../../../assets/img/Flag_of_Saint_Helena.png", timeZone: "Atlantic/St_Helena" },
  { code: '1', shortName: 'KN', name: 'Saint Kitts and Nevis', flagUrl: "../../../assets/img/St_kitts_and_nevis_flag.png", timeZone: "America/St_Kitts" },
  { code: '1', shortName: 'LC', name: 'Saint Lucia', flagUrl: "../../../assets/img/Flag_of_Saint_Lucia.png", timeZone: "America/St_Lucia" },
  { code: '590', shortName: 'MF', name: 'Saint-Martin(French part)', flagUrl: "../../../assets/img/Flag_of_Saint-Martin.png", timeZone: "America/Marigot" },
  { code: '508', shortName: 'PM', name: 'Saint Pierre and Miquelon ', flagUrl: "../../../assets/img/Flag_of_Saint-Pierre_and_Miquelon.png", timeZone: "America/Miquelon" },
  { code: '1', shortName: 'VC', name: 'Saint Vincent and Grenadines', flagUrl: "../../../assets/img/St_vincent_grenadines_flag.png", timeZone: "America/St_Vincent" },
  { code: '685', shortName: 'WS', name: 'Samoa', flagUrl: "../../../assets/img/Flag_of_Samoa.png", timeZone: "Pacific/Apia" },
  { code: '378', shortName: 'SM', name: 'San Marino', flagUrl: "../../../assets/img/Flag_of_San_Marino.png", timeZone: "Europe/San_Marino" },
  { code: '239', shortName: 'ST', name: 'Sao Tome and Principe', flagUrl: "../../../assets/img/Flag_of_Sao_Tome_and_Principe.png", timeZone: "Africa/Sao_Tome" },
  { code: '966', shortName: 'SA', name: 'Saudi Arabia', flagUrl: "../../../assets/img/Flag_of_SaudiArabia.png", timeZone: "Asia/Riyadh" },
  { code: '221', shortName: 'SN', name: 'Senegal', flagUrl: "../../../assets/img/Flag_of_Senegal.png", timeZone: "Africa/Dakar" },
  { code: '381', shortName: 'RS', name: 'Serbia', flagUrl: "../../../assets/img/Flag_of_Serbia.png", timeZone: "Europe/Belgrade" },
  { code: '248', shortName: 'SC', name: 'Seychelles', flagUrl: "../../../assets/img/Flag_of_Seychelles.png", timeZone: "Indian/Mahe" },
  { code: '232', shortName: 'SL', name: 'Sierra Leone', flagUrl: "../../../assets/img/Flag_of_Sierra_Leone.png", timeZone: "Africa/Freetown" },
  { code: '65', shortName: 'SG', name: 'Singapore', flagUrl: "../../../assets/img/Flag_of_Singapore.png", timeZone: "Asia/Singapore" },
  { code: '1', shortName: 'SX', name: 'Sint Maarten', flagUrl: "../../../assets/img/Flag_of_Sint_Maarten.png", timeZone: "America/Lower_Princes" },
  { code: '386', shortName: 'SI', name: 'Slovenia', flagUrl: "../../../assets/img/Flag_of_Slovenia.png", timeZone: "Europe/Ljubljana" },
  { code: '677', shortName: 'SB', name: 'Solomon Islands', flagUrl: "../../../assets/img/Solomon_islands_flag.png", timeZone: "Pacific/Honiara" },
  { code: '252', shortName: 'SO', name: 'Somalia', flagUrl: "../../../assets/img/somalia.png", timeZone: "Africa/Mogadish"},

     
      { code: '27', shortName: 'ZA', name: 'South Africa', flagUrl: "../../../assets/img/Flag_of_South_Africa.png", timeZone: 'Africa/Johannesburg' },
  { code: '82', shortName: 'GS', name: 'South Georgia and the South Sandwich Islands', flagUrl: "../../../assets/img/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.png", timeZone: 'Atlantic/South_Georgia' },
  { code: '211', shortName: 'SS', name: 'South Sudan', flagUrl: "../../../assets/img/Flag_of_South_Sudan.png", timeZone: 'Africa/Juba' },
  { code: '34', shortName: 'ES', name: 'Spain', flagUrl: "../../../assets/img/Spain_flag.png", timeZone: 'Europe/Madrid' },
  { code: '94', shortName: 'LK', name: 'Sri Lanka', flagUrl: "../../../assets/img/Flag_of_Sri_Lanka.png", timeZone: 'Asia/Colombo' },
  { code: '249', shortName: 'SD', name: 'Sudan', flagUrl: "../../../assets/img/Flag_of_Sudan.png", timeZone: 'Africa/Khartoum' },
  { code: '597', shortName: 'SR', name: 'Suriname', flagUrl: "../../../assets/img/Flag_of_Suriname.png", timeZone: 'America/Paramaribo' },
  // { code: ' 855',shortName:'SJ', name: 'Svalbard and Jan Mayen Islands',flagUrl:"../../../assets/img/app_icon_with_name.png" },
  { code: '268', shortName: 'SZ', name: 'Swaziland', flagUrl: "../../../assets/img/Flag_of_Eswatini.png", timeZone: 'Africa/Mbabane' },
  { code: '46', shortName: 'SE', name: 'Sweden', flagUrl: "../../../assets/img/Flag_of_Sweden.svg.png", timeZone: 'Europe/Stockholm' },
  { code: '41', shortName: 'CH', name: 'Switzerland', flagUrl: "../../../assets/img/Flag_of_Switzerland.png", timeZone: 'Europe/Zurich' },
  { code: '963', shortName: 'SY', name: 'Syrian Arab Republic (Syria)', flagUrl: "../../../assets/img/Flag_of_Syria.png", timeZone: 'Asia/Damascus' },
  //T
  { code: '886', shortName: 'TW', name: 'Taiwan', flagUrl: "../../../assets/img/Flag_of_Taiwan.png", timeZone: 'Asia/Taipei' },
  { code: '992', shortName: 'TJ', name: 'Tajikistan', flagUrl: "../../../assets/img/Flag_of_Tajikistan.png", timeZone: 'Asia/Dushanbe' },
  { code: '255', shortName: 'TZ', name: 'Tanzania, United Republic of', flagUrl: "../../../assets/img/Flag_of_Tanzania.png", timeZone: 'Africa/Dar_es_Salaam' },
  { code: '60', shortName: 'TH', name: 'Thailand', flagUrl: "../../../assets/img/Flag_of_Thailand.jpg", timeZone: 'Asia/Bangkok' },
  { code: '670', shortName: 'TL', name: 'Timor-Leste', flagUrl: "../../../assets/img/Flag_of_East_Timor.png", timeZone: 'Asia/Dili' },
  { code: '228', shortName: 'TG', name: 'Togo', flagUrl: "../../../assets/img/Flag_of_Togo.png", timeZone: 'Africa/Lome' },
  { code: '690', shortName: 'TK', name: 'Tokelau', flagUrl: "../../../assets/img/tokelau.png", timeZone: 'Pacific/Fakaofo' },
  { code: '676', shortName: 'TO', name: 'Tonga', flagUrl: "../../../assets/img/Flag_of_Tonga.png", timeZone: 'Pacific/Tongatapu' },
  { code: '1', shortName: 'TT', name: 'Trinidad and Tobago', flagUrl: "../../../assets/img/Flag_of_TrinidadTobago.png", timeZone: 'America/Port_of_Spain' },
  { code: '216', shortName: 'TN', name: 'Tunisia', flagUrl: "../../../assets/img/Flag_of_Tunisia.png", timeZone: 'Africa/Tunis' },
  { code: '90', shortName: 'TR', name: 'Turkey', flagUrl: "../../../assets/img/Turkey_flag.png", timeZone: 'Europe/Istanbul' },
  { code: '993', shortName: 'TM', name: 'Turkmenistan', flagUrl: "../../../assets/img/Flag_of_Turkmenistan.png", timeZone: 'Asia/Ashgabat' },
  { code: '1', shortName: 'TC', name: 'Turks and Caicos Islands', flagUrl: "../../../assets/img/TC-Turks-and-Caicos-Islands-Flag-icon.png", timeZone: 'America/Grand_Turk' },
  { code: '688', shortName: 'TV', name: 'Tuvalu', flagUrl: "../../../assets/img/Flag_of_Tuvalu.jpeg", timeZone: 'Pacific/Funafuti' },

    
      { code: '256', shortName: 'UG', name: 'Uganda', flagUrl: "../../../assets/img/Flag_of_Uganda.png", timeZone: 'Africa/Kampala' },
      { code: '380', shortName: 'UA', name: 'Ukraine', flagUrl: "../../../assets/img/Flag_of_Ukraine.png", timeZone: 'Europe/Kiev' },
      { code: '971', shortName: 'AE', name: 'United Arab Emirates', flagUrl: "../../../assets/img/Flag_of_the_United_Arab_Emirates.png", timeZone: 'Asia/Dubai' },
      { code: '44', shortName: 'GB', name: 'United Kingdom', flagUrl: "../../../assets/img/Flag_of_the_United_Kingdom.png", timeZone: 'Europe/London' },
      { code: '1', shortName: 'US', name: 'United States of America', flagUrl: "../../../assets/img/Flag_of_the_United_States.png", timeZone: 'America/New_York' },
      { code: '598', shortName: 'UY', name: 'Uruguay', flagUrl: "../../../assets/img/Flag_of_Uruguay.png", timeZone: 'America/Montevideo' },
      { code: '1', shortName: 'VI', name: 'US Virgin Islands', flagUrl: "../../../assets/img/Flag_of_the_United_States_Virgin_Islands.png", timeZone: 'America/St_Thomas' },
      { code: '998', shortName: 'UZ', name: 'Uzbekistan', flagUrl: "../../../assets/img/Flag_of_Uzbekistan.png", timeZone: 'Asia/Tashkent' },
      //V
      { code: '678', shortName: 'VU', name: 'Vanuatu', flagUrl: "../../../assets/img/Vanuatu_flag.png", timeZone: 'Pacific/Efate' },
      { code: '58', shortName: 'VE', name: 'Venezuela (Bolivarian Republic)', flagUrl: "../../../assets/img/Flag_of_Venezuela.svg.png", timeZone: 'America/Caracas' },
      { code: '84', shortName: 'VN', name: 'Viet Nam', flagUrl: "../../../assets/img/Flag_of_VietNam.jpeg", timeZone: 'Asia/Ho_Chi_Minh' },
      //W
      { code: '681', shortName: 'WF', name: 'Wallis and Futuna Islands', flagUrl: "../../../assets/img/Wallis_&_Futuna_flag.png", timeZone: 'Pacific/Wallis' },
      //Y-Z
      { code: '967', shortName: 'YE', name: 'Yemen', flagUrl: "../../../assets/img/Flag_of_Yemen.png", timeZone: 'Asia/Aden' },
      { code: '260', shortName: 'ZM', name: 'Zambia', flagUrl: "../../../assets/img/Flag_of_Zambia.png", timeZone: 'Africa/Lusaka' },
      { code: '263', shortName: 'ZW', name: 'Zimbabwe', flagUrl: "../../../assets/img/Flag_of_Zimbabwe.png", timeZone: 'Africa/Harare' },
    
   ];



  }
  formInit() {
    this.myForm = this.fb.group({
      brandName: ['', Validators.required],
      country: ['', Validators.required]
    });
  }

 
  onCountryChange(event: any) {
    if (event.value) {
       this.countryCode = event.value.code;
     this.countryCode1 = event.value.timeZone;
      localStorage.setItem('countryCode', this.countryCode)
      console.log("countryCode00", this.countryCode)
      this.handleCountrySelection(this.countryCode);

       // Get the time zone for the selected country code
    const timeZone =  this.countryCode1;

    // Now you can use the time zone as needed
    console.log("Time Zone:", timeZone);

        // Get the current time in the selected time zone
         this.currentTime = this.getCurrentTimeInTimeZone(timeZone);

        // Now you can use the current time and time zone difference as needed
        console.log("Current Time:", this.currentTime);
    }
  }


getTimeZoneForCountry(countryCode1: string): string {
  // Use a mapping or service to get the time zone for the given country code
  // For example, you can create a mapping object or use a third-party API
  // Here's a simple example with a mapping object:
  const timeZoneMapping = {
    'IN': 'Asia/Kolkata',
    // Add more mappings as needed
  };

  return timeZoneMapping[countryCode1] || 'UTC'; // Default to UTC if not found
}

getCurrentTimeInTimeZone(timeZone: string): string {
  const currentDate = new Date();
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timeZone,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };


  // Format the current time in the selected time zone
  const currentTime = currentDate.toLocaleString('en-US', options);
  return currentTime;
}
  handleCountrySelection(countryCode: string) {
    console.log('Selected Country Code:', countryCode);
  }
  async insertBrand(){
    //   {
    //     "org_id": "stgfgring",
    //     "user_uid": "strfggfing",
    //     "brandName": "Apple",
    //     "status": 0,
    //     "image":
    //     "social_Media_KeyWord": "Feed"
    // } 
    
    const fromData=new FormData();
    this.brandModelList.org_id=await localStorage.getItem('orgId');
    this.brandModelList.user_uid=await localStorage.getItem('userId');
    this.brandModelList.timeZone=this.currentTime;

    console.log('this.brandName--->',this.myForm.controls["brandName"].value);
    this.brandModelList.brandName=this.brandName;
    localStorage.setItem('brandName', this.brandModelList.brandName)
    
    this.brandModelList.status=0;
    this.brandModelList.social_Media_KeyWord='';

    // console.log('this.social_Media_KeyWord--->',this.social_Media_KeyWord);
    console.log('this.brandModelList--->',this.brandModelList);


    if(!this.brandModelList.brandName || !this.brandModelList.timeZone){
      Swal.fire({
        icon: 'error',
        title: 'Alert',
        text: 'Please Add Brand Title & TimeZone',
        confirmButtonText: 'Ok',
        
      });
    }else{
      fromData.append('data',JSON.stringify(this.brandModelList));
     // fromData.append('file',this.profileImageFile);
    (await this.brandService.insertBrand(fromData)).subscribe(async (data: any) => {
   
      console.log('data--->', data);
      if (data['StatusCode'] === 200) {
        const respo = data['Response'];
        await localStorage.setItem('brandId',data['Response'].uid)
        console.log('brand id create =-->',await localStorage.getItem('brandId'));
        
        console.log('respo---->', respo);
      this.router.navigateByUrl('/planer-calender')

      }
    },
      async (error) => {
        // Handle errors
        console.error('Error:', error);

        // You can perform additional actions based on the error status code or message
        if (error.status === 404) {
          Swal.fire({
            icon: 'error',
            title: 'Alert',
            text: 'Data Not Found',
            confirmButtonText: 'Ok',
            
          });
        }
        else
          if (error.status === 500) {
            Swal.fire({
              icon: 'error',
              title: 'Alert',
              text: 'Internal Server Error Plase Try again..',
              confirmButtonText: 'Ok',
            });
          } else {
            console.log('Other error');
          }
        // You can also throw the error again if needed
        throw error;
      })
    }
    }

}

