import { Routes } from "@angular/router";
import { AuthGuard } from "src/app/auth.guard";

export const content: Routes = [

  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: "edit-profile",
    loadChildren: () => import("../../components/edit-profile/edit-profile.module").then((m) => m.EditProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: "create-post",
    loadChildren: () => import("../../components/create-post/create-post.module").then((m) => m.CreatePostModule),
    canActivate: [AuthGuard]
  },
  {
    path: "create-post-social-media",
    loadChildren: () => import("../../components/create-post-p1/create-post-p1.module").then((m) => m.CreatePostP1Module),
    canActivate: [AuthGuard]
  },
  {
    path: "create-post-type",
    loadChildren: () => import("../../components/create-post-type/create-post-type.module").then((m) => m.CreatePostTypeModule),
    canActivate: [AuthGuard]
  },
  {
    path: "brand-name",
    loadChildren: () => import("../../components/brand-name/brand-name.module").then((m) => m.BrandNameModule),
    canActivate: [AuthGuard]
  },
  {
    path: "social-media-accounts",
    loadChildren: () => import("../../components/social-media-accounts/social-media-accounts.module").then((m) => m.SocialMediaAccountsModule),
    canActivate: [AuthGuard]
  },
  {
    path: "organization",
    loadChildren: () => import("../../components/organization/organization.module").then((m) => m.OrganizationModule),
    canActivate: [AuthGuard]
  },
  {
    path: "add-member",
    loadChildren: () => import("../../components/add-member/add-member.module").then((m) => m.AddMemberModule),
    canActivate: [AuthGuard]
  },

  // {
  //   path: "calender",
  //   loadChildren: () => import("../../components/calender/calender.component").then((m) => m.CalenderComponent),
  // },
  {
    path: "planer-calender",
    loadChildren: () => import("../../components/calender/calender.module").then((m) => m.CalenderModule),
    canActivate: [AuthGuard]
  },
  {
    path: "organization",
    loadChildren: () => import("../../components/organization/organization.module").then((m) => m.OrganizationModule),
    canActivate: [AuthGuard]
  },
  {
    path: "add-member",
    loadChildren: () => import("../../components/add-member/add-member.module").then((m) => m.AddMemberModule),
    canActivate: [AuthGuard]
  },
  {
    path: "settings",
    loadChildren: () => import("../../components/settings/settings.module").then((m) => m.SettingsModule),
    canActivate: [AuthGuard]
  },

  {
    path: "social-media-accounts",
    loadChildren: () => import("../../components/social-media-accounts/social-media-accounts.module").then((m) => m.SocialMediaAccountsModule),
    canActivate: [AuthGuard]
  },
  {
    path: "create-post-instagram",
    loadChildren: () => import("../../components/create-post-instagram/create-post-instagram.module").then((m) => m.CreatePostInstagramModule),
    canActivate: [AuthGuard]
  },
  {
    path: "social-media-connection",
    loadChildren: () => import("../../components/social-media-connection/social-media-connection.module").then((m) => m.SocialMediaConnectionModule),
    canActivate: [AuthGuard]
  },
  {
    path: "add-brand",
    loadChildren: () => import("../../components/add-brand/add-brand.module").then((m) => m.AddBrandModule),
    canActivate: [AuthGuard]
  },
  {
    path: "simple",
    loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
   // canActivate: [AuthGuard]
  },
  {
    path: "uplink",
    loadChildren: () => import("../../components/uplink/uplink.module").then((m) => m.UplinkModule)
  },
  {
    path: "uplink-section",
    loadChildren: () => import("../../components/uplink-section/uplink-section.module").then((m) => m.UplinkSectionModule)
  },
  {
    path: "analytics",
    loadChildren: () => import("../../components/analytics/analytics.module").then((m)=>m.AnalyticsModule)
  },
  {
    path: "mentions",
    loadChildren: () => import("../../components/mentions/mentions.module").then((m)=>m.MentionsModule)
  }
];
