import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegistrationModel } from '../models/registration-model';
import { Observable, catchError, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
const baseUrl = `${environment.baseUrl}`;

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) { }

  registration(userData: RegistrationModel) {
    return this.http.post(`${baseUrl}/UserRegistration/UserRegistration`, userData)
  }

  checkUserExist(userName: any) {
    return this.http.get(`${baseUrl}/UserRegistration/CheckUsername?Username=` + userName)
  }

  login(userData: RegistrationModel) {
    return this.http.post(`${baseUrl}/UserRegistration/Login`, userData)
  }

  sendOtp(username: any) {

    return this.http.put(`${baseUrl}/UserRegistration/SendOtpByUsername`, {}, { params: { username: username } })
  }

  verifyOtp(username: any, otp:any) {
    return this.http.post(`${baseUrl}/UserRegistration/VerifyOTPByUserName`, {}, { params: { username: username ,otp:otp} })
  }
  //https://api.impartr.com/api/UserRegistration/ForgetPassword?username=kiranmahanor111@gmail.com&password=Kiran@123
 reSetPass(username:any,password:any){
  return this.http.put(`${baseUrl}/UserRegistration/ForgetPassword`,{},{params : {username:username,password:password}})
 }
 async bookDemo(userData: RegistrationModel)
  {    
    let token = 'Bearer ' + await localStorage.getItem('token')
    // let token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6InNhdXJhYmguc0BhYmhpbmF2aXRzb2x1dGlvbnMuaW4iLCJleHAiOjE3MTg1MTcyNjYsImlzcyI6IktpcmFuIiwiYXVkIjoiSnd0Q1JVRCJ9.krPrU-eRnLtQ47rvUII-EQp-SLSFFXEvNfFG9ezJT7g'
     let headers = new HttpHeaders({
       'Authorization': token
     });
     let options = {
       headers: headers
     };
    return this.http.post(`${baseUrl}/Brand/InsertBookDemo`, userData,options)  
  }


 async question(userData: RegistrationModel) {

  let token='Bearer '+await localStorage.getItem('token')
  console.log('token',token)
  let headers=new HttpHeaders({
    'Authorization':token
  });
  let options={
    headers:headers
  };
  return this.http.post(`${baseUrl}/UserRegistration/AddUserInterest`, userData,options)
}
}