<div class="container-fluid p-0">
  <div >
    <!-- <div class="col-xl-7" [ngStyle]="{'background-image': 'url(assets/images/login/1.jpg)',
                                      'background-size': 'cover',
                                      'background-position': 'center center',
                                      'display': 'block'}">
      <img class="bg-img-cover bg-center d-none" src="assets/images/login/3.jpg" alt="looginpage">
    </div> -->
    <div >
      <div class="login-card">
        <div>
          <div>
            <a class="logo" >
             

              <img class="img-fluid for-light" height="40px"  width="180px" src="../../../assets/images/Impartr_Logo_light.png" alt="looginpage">
              <img class="img-fluid for-dark" height="40px"  width="180px"src="../../../assets/images/Impartr_Logo_dark" alt="looginpage">
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="signup">
              <h4>{{'Create your account' | translate}}</h4>
              <br>
              <div class="form-group">
                <label class="col-form-label">{{'Email Address' | translate}}</label>
                <input class="form-control" formControlName="email" type="email" required=""
                  placeholder="app@yourmail.com">
              </div>
              <div *ngIf="!isValidMobileOrEmail(signup.get('email')?.value) && signup.get('email')?.touched"
                    class="text-danger">
                    {{'Please enter a valid email address.' | translate}}
                </div>
                <div class="form-group">
                  <label class="col-form-label">{{'Password' | translate}}</label>
               
                  <input
                  class="form-control"
                  formControlName="password"
                  [type]="show ? 'text' : 'password'"
                  name="login[password]"
                  required=""
                  placeholder="*********"
                  (paste)="onPaste($event)"
                  (copy)="onCopy($event)"
                  >
                  <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                  
                  </div>
                  <div  *ngIf="signup.hasError('minlength') && signup.get('password')?.hasError('minlength')"class="text-danger">
                    {{'Password must be at least 8 characters.' | translate}}
                    </div>
                  <div class="form-group">
                  <label class="col-form-label">{{'Confirm Password' | translate}}</label>
                  <input
                  class="form-control"
                  formControlName="conformpass"
                  [type]="showCnf ? 'text' : 'password'"
                  name="login[password]"
                  required=""
                  placeholder="*********"
                  (paste)="onPaste($event)"
                  (copy)="onCopy($event)"
                  >
                  <div class="show-hide" (click)="showCnfPassword()" *ngIf="!showCnf"><span class="show"></span></div>
                  <div class="show-hide" (click)="showCnfPassword()" *ngIf="showCnf"><span class="Hide"></span></div>
                  </div>
              <div
                *ngIf="signup.hasError('passwordMismatch') && signup.controls['password'].touched && signup.controls['conformpass'].touched"
                class="text-danger">
                {{'Passwords do not match.' | translate}}
              </div>
              <div class="form-group mb-0">
                <!-- <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox">
                  <label  for="checkbox1"> {{'Agree with' | translate}}<a class="ms-2" routerLink='/'>{{'Privacy Policy' | translate}}</a></label>
                </div> -->
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" formControlName="agree" (click)="checkBox()">
                  <label for="checkbox1">
                    {{ 'Agree with' | translate }}
                    <a class="ms-2" href="https://aidify.co/privacy-policy/" target="_blank">{{ 'Privacy Policy' | translate }}</a>
                  </label>
                </div>
              
                <!-- Display an error message or take appropriate action -->
                <div *ngIf="agree1 ===true">
                  <p style="color: red;">Please agree to the terms before entering the application.</p>
                </div>
                <button (click)="continue()" class="btn btn-primary d-block w-100" type="button">{{'Continue' | translate}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>