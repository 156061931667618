import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-media-post',

  templateUrl: './media-post.component.html',
  styleUrl: './media-post.component.scss'
})
export class MediaPostComponent implements OnInit {
  public config = {
    settings: {
      layout: 'Dubai',
      layout_type: 'ltr',
      layout_version: 'light-only',
      sidebar_type: 'default-sidebar',
      icon: 'stroke-svg'
    },
    color: {
      primary_color: '#3a5880',
      secondary_color: '#7ea3d0'
    }
  }

  ngOnInit(): void {
  }
  constructor(
    private router:Router
  ) {
    if (this.config.settings.layout_type == 'rtl')
      document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);

    document.documentElement.style.setProperty('--theme-deafult', this.config.color.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.config.color.secondary_color);
  }
  currentStep = 1;


  navigateToNextStep() {
    if (this.currentStep < 4) {
      if (this.currentStep==3){
        this.router.navigateByUrl('/questions/question1')
      }
      this.currentStep++;
    }
    console.log(' this.currentStep++', this.currentStep)
   
  
  }

  navigateTobackStep() {
    if (this.currentStep > 1) {
      this.currentStep--;

    }
    console.log(' this.currentStep--', this.currentStep)

  }
}






