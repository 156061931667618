<div class="container-fluid p-0">
  <div class="row">
    <!-- <div class="col-xl-7" [ngStyle]="{'background-image': 'url(assets/images/login/1.jpg)',
    'background-size': 'cover',
    'background-position': 'center center',
    'display': 'block'}">
      <img class="bg-img-cover bg-center d-none" src="assets/images/login/3.jpg" alt="looginpage">
    </div> -->
    <div>
      <div class="login-card center">
        <div>
          <div>
            <a class="logo" >
              <img class="img-fluid for-light" height="40px" width="180px"
                src="../../../assets/images/Impartr_Logo_light.png" alt="looginpage">
              <img class="img-fluid for-dark" height="40px" width="180px"
                src="../../../assets/images/Impartr_Logo_dark.png" alt="looginpage">
            </a>
          </div>
          <div class="login-main" [style]="'margin-top: -5px'">

            <form class="theme-form" [formGroup]="myForm" [style]="'margin-top: -15px'">
              <h4 style="text-align: center;"> {{'Register' | translate}}</h4>
              <p style="text-align: center;"> {{'Enter your personal details to create account' | translate}}</p>
              <div class="form-group">
                <label class="col-form-label pt-0"> {{'Your Name' | translate}}</label>
                <div class="row">
                  <div class="col-6">
                    <input class="form-control" [(ngModel)]="fName" formControlName="firstName" type="text" required="" placeholder="{{'First name' | translate}}">
                  </div>

                

                  <div class="col-6">
                    <input class="form-control"  [(ngModel)]="lName"  formControlName="lastName" type="text" required="" placeholder="{{'Last name' | translate}}">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">{{'Country Code' | translate}}</label>
                <!-- <input class="form-control" required="" placeholder="select you country"> -->
                <div class="form-control">
                  <p-dropdown required=""
                    placeholder="select you country &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                    [options]="countries" formControlName="country" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="selectedCountry" ngDefaultControl (onChange)="onCountryChange($event)"
                    optionLabel="name" [filter]="true" filterBy="name" [virtualScrollItemSize]="38">
                    <ng-template pTemplate="selectedItem">
                      <div class="flex align-items-center gap-4" *ngIf="selectedCountry" style="width: 320px;">
                        <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                          [class]="'flag flag-'+selectedCountry.shortName.toLowerCase()" style="width: 20px" />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{ selectedCountry.name }}
                      </div>
                    </ng-template>
                    <br>
                    <ng-template let-country pTemplate="item">
                      <div class="flex align-items-center gap-4" style="width: 320px;background-color: #fff">
                        <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                          [class]="'flag flag-'+country.shortName.toLowerCase()"
                          style="width: 20px;margin-top: 20px;margin-left:15px;" />
                        <a style="margin-top: 20px;">{{ country.name }}</a>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">{{'Email Address' | translate}}</label>
                <input class="form-control" [(ngModel)]="email" formControlName="email" type="email" required="" placeholder="app@yourmail.com" readonly>
              </div>
              <div class="form-group">
                <label class="col-form-label">{{'Enter Your Number(Optional)' | translate}}</label>
                <input class="form-control"  type="tel"(keypress)="keyPress($event)" minlength=10 maxlength=10
                  formControlName="phoneNumber" required="" placeholder="Phone Number">
              </div>
              <div class="form-group mb-0">
               
                <button class="btn btn-primary d-block w-100" type="button"  (click)="continue()">{{'Create Account' | translate}}</button>
              </div>
              <p class="mt-2 mb-0">{{'Already have an account?' | translate}}<a class="ms-2" [routerLink]="'/auth/login'">{{'Sign in' | translate}}</a></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>