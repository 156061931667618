import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './services/login.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private loginService: LoginService, private router: Router) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    console.log("this.loginService.isAuthenticated --- ", this.loginService.isAuthenticated);
    const token = localStorage.getItem('token')
    if (token) {
      return true;
    } else {
      // Redirect to the login page or any other page
      this.router.navigateByUrl('/auth/login')
      return false;
    }
  }
};