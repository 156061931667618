export class RegistrationModel {

    fullName:any;
    userMobileNumber:any;
    email:any;
    status:any;
    userName:any;
    passWord:any;
    country:any;
    firstName:any;
    lastName:any;
    password:any;
    userUid:any;
    useFor: any;
    purposeOfUse: any;
    teamsMember: any;
    companyMember: any;
    login_Keyword:any;
    userId:any;
    fcmId:any;
    orgId:any;
    brandId:any;     
}
