<div class="container-fluid p-0">
    <div class="row">

        <div>
            <div class="login-card center">
                <div>
                    <div>
                        <a class="logo" [routerLink]="'/'">
                            <img class="img-fluid for-light" height="40px" width="180px"
                                src="../../../assets/images/Impartr_Logo_light.png" alt="looginpage">
                            <img class="img-fluid for-dark" height="40px" width="180px"
                                src="../../../assets/images/Impartr_Logo_dark.pngX" alt="looginpage">
                        </a>
                    </div>
                    <div class="login-main" [style]="'margin-top: -5px'">

                        <form class="theme-form" [formGroup]="myForm" [style]="'margin-top: -15px'">
                            <h4 style="text-align: center;">Create Your First Brand</h4>

                            <ol style="margin-top: 5%;background-color: #FF8c8d;" class="breadcrumb breadcrumb-colored m-b-20">
                                <li class="breadcrumb-item active" style="color: #1c1f20;">Hint : If you will be managing your personal accounts in Impartr then create a
                                    workspace with your own brand name. </li>
                            </ol>

                            <div class="form-group" style="margin-top: -4%;">
                                <label class="col-form-label">Brand Name</label>
                                <input class="form-control" [(ngModel)]="brandName" formControlName="brandName" type="text"
                                    required="" placeholder="Brand Name e.g TATA">
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">Country Time Zone</label>
                                <!-- <input class="form-control" required="" placeholder="select you country"> -->
                                <div class="form-control">
                                    <p-dropdown required=""
                                        placeholder="Country Time Zone &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                        [options]="countries" formControlName="country"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedCountry"
                                        ngDefaultControl (onChange)="onCountryChange($event)" optionLabel="name"
                                        [filter]="true" filterBy="name" [virtualScrollItemSize]="38" [showClear]="true">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="flex align-items-center gap-4" *ngIf="selectedCountry"
                                                style="width: 320px;">
                                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                                                    [class]="'flag flag-'+selectedCountry.shortName.toLowerCase()"
                                                    style="width: 20px" />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                {{ selectedCountry.timeZone }}
                                            </div>
                                        </ng-template>
                                        <br>
                                        <ng-template let-country pTemplate="item">
                                            <div class="flex align-items-center gap-4"
                                                style="width: 320px;background-color: #fff">
                                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                                                    [class]="'flag flag-'+country.shortName.toLowerCase()"
                                                    style="width: 20px;margin-top: 20px;margin-left:15px;" />
                                                <a style="margin-top: 20px;">{{ country.timeZone }}</a>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                            </div>


                            <div class="form-group mb-0" style="margin-top: 7%;">

                                <button class="btn btn-primary d-block w-100" type="button" (click)="insertBrand()">Create
                                    Brand</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
