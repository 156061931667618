import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CreateFaceBookPageModel } from '../models/facebook-page-info'
import { RegistrationModel } from '../models/registration-model'

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  isAuthenticated : Boolean;

  constructor(private http: HttpClient,) { }

  async addFacebookPagesData(userData: CreateFaceBookPageModel) {
     let token = 'Bearer ' + await localStorage.getItem('token')
   // let token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6InNhdXJhYmguc0BhYmhpbmF2aXRzb2x1dGlvbnMuaW4iLCJleHAiOjE3MTg1MTcyNjYsImlzcyI6IktpcmFuIiwiYXVkIjoiSnd0Q1JVRCJ9.krPrU-eRnLtQ47rvUII-EQp-SLSFFXEvNfFG9ezJT7g'
    let headers = new HttpHeaders({
      'Authorization': token
    });
    let options = {
      headers: headers
    };
    return this.http.post('https://api.impartr.com/api/Facebook/AddFacebookPagesData', userData, options)
  }

  login(userData:RegistrationModel){
    return this.http.post('https://api.impartr.com/api/UserRegistration/Login',userData)
    }


    async fcmToken(userData:RegistrationModel){
      let token = 'Bearer ' + await localStorage.getItem('token')
       let headers = new HttpHeaders({
         'Authorization': token
       });
       let options = {
         headers: headers
       };
      return this.http.post('https://api.impartr.com/api/UserRegistration/AddFcmDetails',userData,options)
    }

}
