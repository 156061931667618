import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { CreateFaceBookPageModel } from '../../models/facebook-page-info';
import { FaceBookUserInfoModel } from '../../models/facebook-user-info';
import { InstagramUserInfo } from '../../models/instagram-user-info';
import { RegistrationModel } from '../../models/registration-model';
import { RegistrationService } from '../../services/registration.service';
import { SocialMediaAccountsService } from '../../services/social-media-accounts.service';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrl: './social-media.component.scss'
})
export class SocialMediaComponent {

  fbUser: FaceBookUserInfoModel[] = new Array<FaceBookUserInfoModel>();
  fbPage: CreateFaceBookPageModel = new CreateFaceBookPageModel();
  registrationModel: RegistrationModel = new RegistrationModel();
  fb_data: any
  socialUser: SocialUser;
  filteredAccounts: any[];
  instaUser: InstagramUserInfo = new InstagramUserInfo()

  constructor(public socialAuthService: SocialAuthService,
    public router: Router,
    private socialMediaAccountsService: SocialMediaAccountsService,
    private registrationService: RegistrationService) {
    if (this.config.settings.layout_type == 'rtl')
      document.getElementsByTagName('html')[0].setAttribute('dir',
        this.config.settings.layout_type);
    document.documentElement.style.setProperty('--theme-deafult',
      this.config.color.primary_color);
    document.documentElement.style.setProperty('--theme-secondary',
      this.config.color.secondary_color);
  }

  public config = {
    settings: {
      layout: 'Dubai',
      layout_type: 'ltr',
      layout_version: 'light-only',
      sidebar_type: 'default-sidebar',
      icon: 'stroke-svg'
    },
    color: {
      primary_color: '#3a5880',
      secondary_color: '#7ea3d0'
    }
  }

  connectWithFacebook(): void {

    // Auth login
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(async (data) => {
      console.log("data : ", data);
      this.socialUser = data;
      this.fb_data = data;
      await localStorage.setItem("fb_data", this.fb_data)

      // fetch facebook pages {data.id = fb user id & data.authToken = fb user access token}
      const facebook_pages = await fetch(` https://graph.facebook.com/v9.0/` + data.id + `/accounts?access_token=${data.authToken}`)
      //console.log("facebook_pages : ------------- ", facebook_pages);
      const myJson = await facebook_pages.json();

      for (let i = 0; i < myJson.data.length; i++) {
        this.fbUser[i] = myJson.data[i];
      }

      for (let i = 0; i < this.fbUser.length; i++) {
        const fb_pages_access_token = this.fbUser[i].access_token;

        // increase validity of page access token to 2 months
        const extended_access_token = await fetch(`https://graph.facebook.com/v18.0/oauth/access_token?grant_type=fb_exchange_token&client_id=725756832392921&client_secret=262ec0d67eceef2632ce5ca916fafc69&fb_exchange_token=${fb_pages_access_token}`)
        const access_token_json = await extended_access_token.json();
        this.fbPage.accessToken = access_token_json.access_token;
        this.fbPage.pageCategory = this.fbUser[i].category;
        this.fbPage.pageId = this.fbUser[i].id;
        this.fbPage.pageName = this.fbUser[i].name;
        this.fbPage.socialMediaUserId = data.id;
        this.fbPage.userUId = await localStorage.getItem('userId')
        this.fbPage.orgUId = await localStorage.getItem('orgId')
        this.fbPage.socialMediaKeyWord = 'Facebook';
        this.fbPage.loginStatus = '1';
        this.fbPage.brandId = await localStorage.getItem('brandId')
        console.log("this.fbPage : ", this.fbPage);
        (await this.socialMediaAccountsService.addFacebookPagesData(this.fbPage)).subscribe(async (data: any) => {
          console.log("facebook page data : ", data);
        })

      }
    });
  }


  connectWithInstagram() {

    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(async (data) => {
      console.log("data : ", data);
      this.socialUser = data;
      this.fb_data = data;
      await localStorage.setItem("fb_data", this.fb_data)

      // fetch Instagram business id {data.id = fb user Id & data.authToken = fb user access token} 
      const ig_buisness_id = await fetch(`https://graph.facebook.com/v18.0/` + data.id + `/accounts?fields=instagram_business_account,name,category&access_token=${data.authToken}`)
      const ig_buisness_id_json = await ig_buisness_id.json();
      const ig_data = ig_buisness_id_json.data;
      console.log("ig_data : ", ig_data);
      console.log("ig_data length : ", ig_data.length);
      this.filteredAccounts = ig_data.filter(item => item.instagram_business_account !== undefined);
      console.log("this.filteredAccounts : ", JSON.stringify(this.filteredAccounts));

      for (let i = 0; i < this.filteredAccounts.length; i++) {
        console.log("i ", i);

        const ig_business_account_id = this.filteredAccounts[i].instagram_business_account.id;
        // fetch IG name & username {ig_business_account_id = ig user Id & data.authToken = fb user access token}
        const ig_business_account_name = await fetch(`https://graph.facebook.com/v18.0/` + ig_business_account_id + `?fields=name,username&access_token=${data.authToken}`)
        const ig_business_account_name_json = await ig_business_account_name.json();
        const page_name = this.filteredAccounts[i].name
        const page_id = this.filteredAccounts[i].id
        const page_category = this.filteredAccounts[i].category
        const social_media_userId = data.id

        // Insert instagram user info to db
        this.instaUser.instaIgId = ig_business_account_id
        this.instaUser.instaIgName = ig_business_account_name_json.name
        this.instaUser.instaIgUserName = '@' + ig_business_account_name_json.username
        this.instaUser.pageName = page_name
        this.instaUser.pageId = page_id
        this.instaUser.pageCategory = page_category
        this.instaUser.accessToken = data.authToken
        this.instaUser.userUId = await localStorage.getItem('userId')
        this.instaUser.createdBy = await localStorage.getItem('userId')
        this.instaUser.socialMediaUserId = social_media_userId
        this.instaUser.orgUId = await localStorage.getItem('orgId')
        this.instaUser.brandId = await localStorage.getItem('brandId')
        this.instaUser.loginStatus = '1'
        this.instaUser.cuurentstatus = '1'
        this.instaUser.social_Media_Keyword = 'Instagram';

        (await this.socialMediaAccountsService.addInstagramUserInfo(this.instaUser)).subscribe(async (data: any) => {
          console.log("instagram  data : ", data);
        })
      }
    });
  }

  async book_demo() {

    const firstName = await localStorage.getItem('firstName')
    const lastName = await localStorage.getItem('lastName')
    const email = await localStorage.getItem('email')
    const userId = await localStorage.getItem('userId')

    this.registrationModel.firstName = firstName
    this.registrationModel.lastName = lastName
    this.registrationModel.email = email
    this.registrationModel.userUid = userId

    ;(await this.registrationService.bookDemo(this.registrationModel)).subscribe((data: any) => {
      console.log("data : ", data);
      if (data['StatusCode'] === 200) {
        this.router.navigateByUrl('/planer-calender')
      }

    })

  }

  skip_explore(){
    this.router.navigateByUrl('/planer-calender')
  }
}
