export class CreateFaceBookPageModel {

    socialMediaUserId: any;
    pageId: any;
    pageName: any;
    pageCategory: any;
    accessToken: any;
    userUId: any
    orgUId: any
    brandId: any
    socialMediaKeyWord: any
    loginStatus: any
    profileImageLink:any


}
