import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";
import {RegisterComponent} from "./auth/register/register.component"
import { SignUpComponent } from "./auth/sign-up/sign-up.component";
import { LoginComponent } from "./auth/login/login.component";
import { ForgetPasswordComponent } from "./auth/forget-password/forget-password.component";
import {  OtpComponent} from "./auth/otp/otp.component";
import {  SocialMediaComponent} from "./auth/social-media/social-media.component";
import { Question1Component } from "./quetions/question1/question1.component";
import { Question4Component } from "./quetions/question4/question4.component";
import { Question2Component } from "./quetions/question2/question2.component";
import { Question3Component } from "./quetions/question3/question3.component";
import { CreateBrandComponent } from "./auth/create-brand/create-brand.component";
import{MediaPostComponent}from "./auth/media-post/media-post.component"
import { StartComponent } from "./auth/start/start.component";
import { AuthGuard } from '../app/auth.guard'
import { SimplePageModule } from '../app/components/simple-page/simple-page.module'

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth/login",
    pathMatch: "full",
  },
  {path: 'auth/register', component: RegisterComponent},
  {path:'auth/sign-up',component:SignUpComponent},
  {path:'auth/forgot-password',component:ForgetPasswordComponent},
  {path:'auth/otp',component:OtpComponent},
  {path:'auth/create-brand',component:CreateBrandComponent,canActivate: [AuthGuard]},
  {path:'auth/social-media',component:SocialMediaComponent,canActivate: [AuthGuard]},

  {path:'auth/media-post',component:MediaPostComponent,canActivate: [AuthGuard]},
  {path:'auth/start',component:StartComponent,canActivate: [AuthGuard]},

  {path:'questions/question1',component:Question1Component,canActivate: [AuthGuard]},
  {path:'questions/question4',component:Question4Component,canActivate: [AuthGuard]},

  {path:'questions/question2',component:Question2Component,canActivate: [AuthGuard]},
  {path:'questions/question3',component:Question3Component,canActivate: [AuthGuard]},
 

 
  {
    path: "",
    component: ContentComponent,
    children: content,
    //canActivate: [AuthGuard]

  },
  {
    path: 'auth/login',
    component: LoginComponent
  },


  {
    path: "",
    component: FullComponent,
    children: full

  },
  {
    path: "**",
    redirectTo: "",
  },

];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
