<div class="div" style="margin-left: 10%;">

  <div class="div-8">
    <div class="div-9">
      <div class="div-10">
        <img height="100%" width="60px" src="../../../assets/images/avtar/question3.png">
      </div>
      <div class="div-16">
        <div class="div-17">Question 3 of 4</div>
        <div class="div-18">
          How Many on your team will help manage social media ?
        </div>
        <div style="color: #757575;
          text-align: left;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;margin-top: 10px;">Your answer will help us personalize your experience</div>
        <div class="div-19">
          <div class="div-20">
            <!--image Just me-->
            <div *ngIf="imageSocialMediaManager===false" (click)="onChangeImage(1)">
              <div class="column">
                <div class="div-21">
                  <img loading="lazy" src="../../../assets/images/avtar/justMePeople.png" class="img-4" />
                  <div class="div-22">
                    Just me
                  </div>
                </div>
              </div>
            </div>
            <!-- color image Just me-->
            <div *ngIf="imageSocialMediaManager===true" (click)="onChangeImage(2)">
              <div class="column">
                <div class="div-21" style="background-color: #3B5880;">
                  <img loading="lazy" style="border-radius: 55px;background-color: #fff;"
                    src="../../../assets/images/avtar/justMePeople.png" class="img-4" />
                  <div class="div-22" style="color: #fff;">
                    Just me
                  </div>
                </div>
              </div>
            </div>
            <!-- image   2-10 peoples-->
            <div *ngIf="imageSmallBusinessOwner===false" (click)="onChangeImage(3)">
              <div class="column">
                <div class="div-21">
                  <img loading="lazy" src="../../../assets/images/avtar/2-3People.png" class="img-4" />
                  <div class="div-22">
                    2-3 peoples
                  </div>
                </div>
              </div>
            </div>
            <!-- color image   2-3 peoples-->

            <div *ngIf="imageSmallBusinessOwner===true" (click)="onChangeImage(4)">
              <div class="column">
                <div class="div-21" style="background-color: #3B5880;">
                  <img loading="lazy" style="border-radius: 55px;background-color: #fff;"
                    src="../../../assets/images/avtar/2-3People.png" class="img-4" />
                  <div class="div-22" style="color: #fff;">
                    2-3 peoples
                  </div>
                </div>
              </div>
            </div>
            <!-- image  4-6 peoples-->
            <div *ngIf="imageCreator===false" (click)="onChangeImage(5)">
              <div class="column">
                <div class="div-21">
                  <img loading="lazy" src="../../../assets/images/avtar/4-6peoples.png" class="img-4" />
                  <div class="div-22">4-6 peoples</div>
                </div>

              </div>
            </div>
            <!-- color image  4-6 peoples-->
            <div *ngIf="imageCreator===true" (click)="onChangeImage(6)">
              <div class="column">

                <div class="div-21" style="background-color: #3B5880;">
                  <img loading="lazy" style="border-radius: 55px;background-color: #fff;"
                    src="../../../assets/images/avtar/4-6peoples.png" class="img-4" />
                  <div class="div-22" style="color: #fff;">4-6 peoples</div>
                </div>

              </div>
            </div>

            <!--More than 6 people-->
            <div  *ngIf="imageMarketingAgency===false" (click)="onChangeImage(7)">
              <div class="column">
                <div class="div-21">
                  <img loading="lazy" src="../../../assets/images/avtar/Morethan4people.png" class="img-11" />
                  <p class="div-22">
                    More than 6 people
                  </p>
                </div>
              </div>
            </div>
            <!-- color image More than 6 people-->
            <div  *ngIf="imageMarketingAgency===true" (click)="onChangeImage(8)">
              <div class="column">
                <div class="div-21" style="background-color: #3B5880;">
                  <img loading="lazy" style="border-radius: 90px;background-color: #fff;width: 90%;"
                    src="../../../assets/images/avtar/Morethan4people.png" class="img-11" />
                  <div class="div-22" style="color: #fff;">
                    More than 6 people
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="div-35" (click)="routeToQue4()">
      <div class="div-36">Next</div>
      <img src="../../../assets/icon/arrow-right.png">
    </div>

  </div>

</div>