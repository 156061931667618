import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { error } from 'console';
import { RegistrationService } from 'src/app/services/registration.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  forgetPass!: FormGroup;
  public show: boolean = false;
  public show1: boolean = false;
  username: any;
  otp: any;
  showPass: boolean = false;
  newPassword: any;
  confPassword: any;
  email: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private registration: RegistrationService,
    private translate:TranslateService
  ) {

   }

  ngOnInit() {
    this.formInit();
    this.email=localStorage.getItem('forgotEmail')
    console.log('email',this.email)
    this.forgetPass.controls['email'].setValue(this.email);
  }

  showPassword() {
    this.show = !this.show;
  }

  showPassword1() {
    this.show1 = !this.show1;
  }
  formInit() {
    this.forgetPass = this.formBuilder.group({
      email: ['', [Validators.pattern(/^([^\s@]+@[^\s@]+\.[^\s@]+)|([1-9]\d{9})$/)]],
      newPassword: ['', [Validators.required, Validators.minLength(8)], Validators.maxLength(20)],
      conformpass: ['', Validators.required],
      otp: ['',]
    });
  }
  isValidEmail(value: string): boolean {
    // const mobileRegex = /^[1-9]\d{9}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // return mobileRegex.test(value) || emailRegex.test(value);
    return emailRegex.test(value);

  }
  onOtpChange(event: any) {
    this.otp = event;
    console.log(this.otp);
  }
  async sendOtp() {

    this.username = this.forgetPass.get('email').value;

    localStorage.setItem('email', this.username);

    if (!this.username) {
      Swal.fire({
        icon: 'error',
        title: 'Alert',
        text: 'Please Enter User Name',
        confirmButtonText: 'Ok',
      });
    } else if (!this.isValidEmail(this.forgetPass.get('email')?.value)) {

    }
    else {
      console.log('else ');

      (await this.registration.sendOtp(this.username)).subscribe(
        (data: any) => {
          console.log('Data:', data);
          if (data['StatusCode'] === 200) {
            Swal.fire({
              icon: 'success',
              text: 'Otp Send SuccessFully',
              confirmButtonText: 'Ok',
            });
          }
        },
        async (error) => {
          // Handle errors
          console.error('Error:', error);
          if (error.status === 404) {
            Swal.fire({
              icon: 'error',
              title: 'Alert',
              text: 'Email Not Registered',
              confirmButtonText: 'Ok',
            });
          }
          else if (error.status === 500) {
            Swal.fire({
              icon: 'error',
              title: 'Alert',
              text: 'Please Try again...',
              confirmButtonText: 'Ok',
            });
          } else {
            console.log('Other error');
          }
          throw error;

        })
    }
  }
  async VerifyOtp() {

    this.username = this.forgetPass.get('email').value;

    localStorage.setItem('email', this.username);

    if (!this.username) {
      Swal.fire({
        icon: 'error',
        title: 'Alert',
        text: 'Please Enter User Name',
        confirmButtonText: 'Ok',
      });
    } else if (!this.isValidEmail(this.forgetPass.get('email')?.value)) {

    }
    else {
      console.log('else ');

      (await this.registration.verifyOtp(this.username, this.otp)).subscribe(
        (data: any) => {
          console.log('Data:', data);
          if (data['StatusCode'] === 200) {
            this.showPass = true;
            this.otp = ''
          }
        },
        async (error) => {
          // Handle errors
          console.error('Error:', error);
          if (error.status === 404) {
            Swal.fire({
              icon: 'error',
              title: 'Alert',
              text: 'Email Not Registered',
              confirmButtonText: 'Ok',
            });
          }
          else if (error.status === 500) {
            Swal.fire({
              icon: 'error',
              title: 'Alert',
              text: 'Please Try again...',
              confirmButtonText: 'Ok',
            });
          } else if (error.status === 409) {
            Swal.fire({
              icon: 'error',
              title: 'Alert',
              text: 'Please Enter Valid Otp',
              confirmButtonText: 'Ok',
            });
          }
          throw error;

        })
    }
  }

  // resetPassWord() {
  //   this.username = this.forgetPass.get('email').value;
  //   this.newPassword = this.forgetPass.get('newPassword').value;
  //   this.confPassword = this.forgetPass.get('conformpass').value
  //   console.log(this.newPassword.length);

  //   if (!this.username || !this.newPassword || !this.confPassword) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Alert',
  //       text: 'Please Fill All Required Field',
  //       confirmButtonText: 'Ok',
  //     });
  //   } else if (!this.isValidEmail(this.forgetPass.get('email')?.value)) {

  //   }
    
  //   if (this.newPassword !== this.confPassword) {
  //     this.forgetPass.setErrors({ passwordMismatch: true });

  //     console.log('passwordMismatch ');
  //   }
  //   if (this.newPassword.length < 8) {
  //     this.forgetPass.setErrors({ minlength: true });

  //     console.log('minlength ');
  //   }
  //   else {
  //     this.forgetPass.setErrors(null);
  //     this.registration.reSetPass(this.username, this.newPassword).subscribe((data: any) => {
  //       if (data['StatusCode'] === 200) {
  //         Swal.fire({
  //           icon: 'success',
  //           text: 'Password Updated SuccessFully',
  //           confirmButtonText: 'Ok',
  //         });
  //         this.router.navigateByUrl('/auth/login')
  //       } (error) => {
  //         if (error.status === 404) {
  //           // Swal.fire({
  //           //   icon: 'error',
  //           //   title: 'Alert',
  //           //   text: 'Email Not Registered',
  //           //   confirmButtonText: 'Ok',
  //           // });
  //         }
  //         else if (error.status === 500) {
  //           Swal.fire({
  //             icon: 'error',
  //             title: 'Alert',
  //             text: 'Please Try again...',
  //             confirmButtonText: 'Ok',
  //           });
  //         } else if (error.status === 409) {
  //           // Swal.fire({
  //           //   icon: 'error',
  //           //   title: 'Alert',
  //           //   text: 'Please Enter Valid Pa',
  //           //   confirmButtonText: 'Ok',
  //           // });
  //         }
  //         throw error;
  //       }
  //     })
  //   }
  // }



  resetPassWord() {
    this.username = this.forgetPass.get('email').value;
    this.newPassword = this.forgetPass.get('newPassword').value;
    this.confPassword = this.forgetPass.get('conformpass').value
    console.log(this.newPassword.length);
    
    if (!this.username || !this.newPassword || !this.confPassword) {
    Swal.fire({
    icon: 'error',
    title: 'Alert',
    text: 'Please Fill All Required Field',
    confirmButtonText: 'Ok',
    });
    } else if (!this.isValidEmail(this.forgetPass.get('email')?.value)) {
    
    }
    
    else if (this.newPassword.length < 8) {
    this.forgetPass.setErrors({ minlength: true });
    
    console.log('minlength ');
    } else
    if (this.newPassword !== this.confPassword) {
    this.forgetPass.setErrors({ passwordMismatch: true });
    // this.signup.setErrors({ passwordMismatch: true });
    
    console.log('passwordMismatch ');
    }
    else{
    this.forgetPass.setErrors(null);
    this.registration.reSetPass(this.username, this.newPassword).subscribe((data: any) => {
    if (data['StatusCode'] === 200) {
    Swal.fire({
    icon: 'success',
    text: 'Password Updated SuccessFully',
    confirmButtonText: 'Ok',
    });
    this.router.navigateByUrl('/auth/login')
    } (error) => {
    if (error.status === 404) {
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Alert',
    //   text: 'Email Not Registered',
    //   confirmButtonText: 'Ok',
    // });
    }
    else if (error.status === 500) {
    Swal.fire({
    icon: 'error',
    title: 'Alert',
    text: 'Please Try again...',
    confirmButtonText: 'Ok',
    });
    } else if (error.status === 409) {
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Alert',
    //   text: 'Please Enter Valid Pa',
    //   confirmButtonText: 'Ok',
    // });
    }
    throw error;
    }
    })
    }
    }

}