import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VerifyOtp } from '../models/verify-otp';

@Injectable({
  providedIn: 'root'
})
export class VerifyOtpService {

  constructor(private http:HttpClient) {

   }


  //  async verifyOtp(otpDetail:VerifyOtpModel){
  //   let token='Bearer '+await  this.storage.get('token');
  //   console.log("token in join or create organization->",token);
    
  //   let headers = new HttpHeaders({
  //     'Authorization': token
  //   });
  //   let options = {
  //     headers:headers
  //   };
  //   console.log("options->",options);
  
  // return this.http.post("https://api.cuelab.net/api/Registration/OtpVarification",otpDetail,options)
  // }

   async otpVerify(otpData:VerifyOtp){
    let token='Bearer '+await localStorage.getItem('token')
    // let token='Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImsxIiwiZXhwIjoxNzAyMzc4OTQzLCJpc3MiOiJLaXJhbiIsImF1ZCI6Ikp3dENSVUQifQ.oqY_ZUFp_0uS7fwr8x_KCgtDq6xxY0F1r_BpxuCKmGE'
    console.log('token',token)
    let headers=new HttpHeaders({
      'Authorization':token
    });
    let options={
      headers:headers
    };

    return this.http.post('https://api.impartr.com/api/UserRegistration/VerifyOTP',otpData,options)

   }

   async otpResend(otpData:VerifyOtp){
    let token='Bearer '+await localStorage.getItem('token')
    console.log('token',token)
    let headers=new HttpHeaders({
      'Authorization':token
    });
    let options={
      headers:headers
    };
    return this.http.post('https://api.impartr.com/api/UserRegistration/ResendOTP',otpData,options)
   }

   
}
